import * as React from 'react';
import {Box, Heading, Expand, Popover, Template, Button, googleLogout} from '@pluto-tv/assemble';

import {SimpleAvatar} from 'components/simpleAvatar';
import {logout} from 'features/user/userSlice';
import {useAppDispatch} from 'app/store/hooks';

interface IUserProps {
  avatar?: string;
  name: string;
  email: string;
  isExpanded: boolean;
}

const LogoutButton = () => {
  const dispatch = useAppDispatch();

  return (
    <Button
      icon='google'
      iconColor='white'
      size='medium'
      onClick={() => {
        googleLogout();
        dispatch(logout());
      }}
    >
      Logout
    </Button>
  );
};

export default ({avatar, name, email, isExpanded = false}: IUserProps): JSX.Element => (
  <Expand isExpanded={isExpanded} width='12.5rem'>
    <Template label='fixed'>
      <Popover allowedPlacements={['top-start', 'bottom-end']}>
        <Template label='trigger'>
          <SimpleAvatar name={name} src={avatar} sidebar={true}></SimpleAvatar>
        </Template>
        <Template label='popover'>
          <LogoutButton />
        </Template>
      </Popover>
    </Template>
    <Template label='expandable'>
      <Popover triggerDisplay='block' allowedPlacements={['top-start', 'bottom-end']}>
        <Template label='trigger'>
          <Box paddingTop='xsmall' hideAt='mobile'>
            <Heading level='h4' truncate={true} truncateBackgroundHover='cavern'>
              {email}
            </Heading>
          </Box>
        </Template>
        <Template label='popover'>
          <LogoutButton />
        </Template>
      </Popover>
    </Template>
  </Expand>
);
