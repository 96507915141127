import * as React from 'react';
import {Button, Cluster, Dialog, Heading, Icon, Table, TdMultilineTruncate, Template, TSize} from '@pluto-tv/assemble';
import {reorderList} from 'helpers/dragAndDrop';
import carouselRoutes from 'routes/programming.routes';
import {IHubCarousel} from 'models/hubConfigs';
import {isEqual} from 'lodash-es';

interface IHubConfigProps {
  hubCarousels: IHubCarousel[];
  onClose: () => void;
  onApplyOrder: (hubCarousels: IHubCarousel[]) => void;
}

const ReorderPanel = ({hubCarousels, onClose, onApplyOrder}: IHubConfigProps): JSX.Element => {
  const [carouselList, setCarouselList] = React.useState<IHubCarousel[]>([]);
  const [selectedRows, setSelectedRows] = React.useState<IHubCarousel[]>([]);

  React.useEffect(() => {
    setCarouselList([...(hubCarousels || [])]);
  }, [hubCarousels]);

  const handleApplyOrder = () => {
    onApplyOrder(carouselList);
    onClose();
  };

  const handleDrop = (_from: string, _to: string, fromIndex: number[], toIndex: number) => {
    setCarouselList(reorderList(fromIndex, toIndex, carouselList));
  };

  const handleSelect = React.useCallback((rows: IHubCarousel[]) => {
    setSelectedRows(rows);
  }, []);

  const handleMoveUp = React.useCallback(
    (index: number) => {
      setCarouselList(reorderList([index], index - 1, carouselList));
    },
    [carouselList],
  );

  const handleMoveDown = React.useCallback(
    (index: number) => {
      setCarouselList(reorderList([index], index + 2, carouselList));
    },
    [carouselList],
  );

  const isApplyOrderDisabled = React.useMemo(() => {
    const modelIds = (hubCarousels || []).map(carousel => carousel.id);
    const carouselListIds = (carouselList || []).map(carousel => carousel.id);

    return isEqual(modelIds, carouselListIds);
  }, [carouselList, hubCarousels]);

  const columns = React.useMemo(
    () => [
      {
        label: 'Order No.',
        colWidth: '6.875rem' as TSize,
        transform: (row: IHubCarousel, _, index: number) => <div>{index + 1}</div>,
      },
      {
        label: 'Carousel Name',
        transform: (row: IHubCarousel) => (
          <TdMultilineTruncate
            row={row}
            text={row.displayName}
            title={row.displayName}
            url={carouselRoutes.paths.carouselEditDetailsPage.replace(':id', row.id)}
            truncateOnLine={2}
          />
        ),
      },
      {
        label: 'Actions',
        colWidth: '3.125rem' as TSize,
        transform: (row: IHubCarousel, _, index: number) => (
          <Cluster space='xxsmall'>
            <Icon
              disabled={index === 0 || selectedRows.length > 1}
              onClick={() => handleMoveUp(index)}
              icon='directionup'
            />
            <Icon
              disabled={index === carouselList.length - 1 || selectedRows.length > 1}
              onClick={() => handleMoveDown(index)}
              icon='directiondown'
            />
          </Cluster>
        ),
      },
    ],
    [carouselList.length, handleMoveDown, handleMoveUp, selectedRows.length],
  );

  return (
    <Dialog isOpen={true} onClose={onClose} height='75%' width='650px' id='reorderPanel'>
      <Template label='header'>
        <Heading level='h2'>Carousel List</Heading>
      </Template>
      <Template label='body'>
        <Table<IHubCarousel>
          onDrop={handleDrop}
          draggable={true}
          predicate='id'
          onSelect={handleSelect}
          dragKey='carouselListReorder'
          dropKeys={['carouselListReorder']}
          id='carouselListReorder'
          selectable='multiple'
          wrapContent={true}
          cols={columns}
          rows={carouselList}
          selected={selectedRows}
        />
      </Template>
      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='cancelBtn' ghost={true} size='small' onClick={onClose}>
              Cancel
            </Button>
            <Button
              id='applyOrderBtn'
              type='primary'
              size='small'
              onClick={handleApplyOrder}
              state={isApplyOrderDisabled ? 'disabled' : ''}
            >
              Apply Order
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Dialog>
  );
};

export default ReorderPanel;
