import * as React from 'react';
import {Accordion, Template, Cluster, Heading, Checkbox, Box, Stack} from '@pluto-tv/assemble';
import {TPermissionChangeCallback} from '../utils';
import PermissionItem from './permissionsItem';

export interface IPermissionSubGroupProps {
  selectedPermissions: string[];
  name: string;
  content: any;
  onPermissionChange: TPermissionChangeCallback;
  readonly: boolean;
}

export default ({
  selectedPermissions,
  name,
  content,
  onPermissionChange,
  readonly = false,
}: IPermissionSubGroupProps): JSX.Element => {
  const allPermissions = Object.values(content).map((p: any) => p.name);
  const selectedGroupPermissions = selectedPermissions.filter(permission => allPermissions.find(p => p === permission));

  const isReadOnly = React.useMemo(() => {
    if (readonly) return readonly;

    let isReadOnly = false;

    content.forEach(contentItem => {
      const dependsOn = contentItem.dependsOn;
      // If some of the dependecies are part of this group, the SelectAll checkbox should be enable.
      if (!dependsOn.length || dependsOn.some(d => d === contentItem.name)) {
        isReadOnly = false;
        return;
      }

      // If in the selected permissions the dependencies is not present then the SelectAll checkbox should be disable.
      dependsOn.forEach(item => {
        isReadOnly = !(selectedPermissions.some(sp => item === sp) || allPermissions.find(ap => ap === item));
      });
    });

    return isReadOnly;
  }, [allPermissions, content, readonly, selectedPermissions]);

  return (
    <Accordion key={name}>
      <Template label='trigger'>
        <Cluster space='small' justify='space-between' fullWidth={true} align='center'>
          <Heading level='h4'>{name}</Heading>
          <Checkbox
            label='Enable All'
            onChange={enable => onPermissionChange(allPermissions, enable)}
            value={allPermissions.length === selectedGroupPermissions.length}
            indeterminate={
              selectedGroupPermissions.length > 0 && selectedGroupPermissions.length !== allPermissions.length
            }
            state={isReadOnly ? 'disabled' : ''}
          />
        </Cluster>
      </Template>
      <Template label='body'>
        <Box paddingTop='large' paddingLeft='large'>
          <Stack space='large'>
            {Object.keys(content).map(entryItem => (
              <PermissionItem
                key={content[entryItem].name}
                selectedPermissions={selectedPermissions}
                name={content[entryItem].name}
                displayName={content[entryItem].displayName}
                dependsOn={content[entryItem].dependsOn}
                onPermissionChange={(permissions: string[], enable: boolean) => onPermissionChange(permissions, enable)}
                readonly={isReadOnly}
              />
            ))}
          </Stack>
        </Box>
      </Template>
    </Accordion>
  );
};
