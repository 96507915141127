import * as React from 'react';
import {cloneDeep} from 'lodash-es';
import {Stack, Grid, findParentByClass, Notification, Help} from '@pluto-tv/assemble';
import AddBox from 'components/addBox';
import Billboard from 'components/billboard';
import {ICampaignSlot, ISlotBadge} from 'models/campaigns';
import {rewriteImageUrl} from 'utils/imageUtils';

export interface IPrimetimeBillboardProps {
  billboards: ICampaignSlot[];
  saveErrors?: string[];
  helpText?: string;
  removeHandler: (index: number) => void;
  searchHandler: (position: number) => void;
  replaceHandler: (position: number, replaceHandler?: boolean) => void;
  changeHandler: (items: ICampaignSlot[]) => void;
  readonly: boolean;
}

const getEmptySlots = billboards => {
  if (!billboards || !billboards.length) {
    return Array.from(Array(12).keys());
  } else if (billboards.length && billboards.length < 12) {
    return Array.from(Array(12 - billboards.length).keys());
  }

  return [];
};

// This would move into assemble
const moveItem = (arr, fromI, toI) => {
  const clonedArr = cloneDeep(arr);

  const elem = clonedArr[fromI];
  clonedArr.splice(fromI, 1);
  clonedArr.splice(toI, 0, elem);

  return clonedArr;
};

export default ({
  billboards,
  removeHandler,
  searchHandler,
  saveErrors = [],
  replaceHandler,
  changeHandler,
  helpText = '',
  readonly = false,
}: IPrimetimeBillboardProps): JSX.Element => {
  const emptySlots = getEmptySlots(billboards);

  const billboardErrors = billboards.some(b => b.errors?.some(e => e.field === 'ContentID'));
  const slotError = saveErrors.filter(s => s);
  const pluralize = slotError.length > 1;

  const onSlotChange = (position: number, badge: ISlotBadge) => {
    changeHandler(billboards.map((item, index) => (index === position ? {...item, badge: badge} : item)));
  };

  const onSlotMoved = (sourceIndex, targetIndex) => {
    changeHandler(moveItem(billboards, sourceIndex, targetIndex));
  };

  return (
    <Stack space='xlarge'>
      <Stack space='small'>
        {helpText && helpText.length > 0 ? (
          <Help state='error'>{helpText}</Help>
        ) : (
          <Help state='normal'>In order to publish, a minimum of 5 carousels is required</Help>
        )}
        {billboardErrors && (
          <Notification type='error'>
            One or more carousels have invalid content. The content of the carousel may have been deleted.
          </Notification>
        )}

        {slotError.length > 0 && (
          <Notification type='error'>
            {`Slot${pluralize ? 's' : ''} ${slotError.join(', ')} contain${
              pluralize ? '' : 's an'
            } invalid content item${pluralize ? 's' : ''}. Please update to continue.`}
          </Notification>
        )}
      </Stack>
      <Grid gap='xlarge'>
        {billboards.map((billboard, i) => (
          <Billboard
            readonly={readonly}
            state={(billboard.errors || []).length > 0 || saveErrors[i] ? 'error' : undefined}
            onRemove={() => removeHandler(i)}
            onReplace={() => replaceHandler(i)}
            onReplaceImages={() => replaceHandler(i, true)}
            onDrop={ev => {
              const fromIndex = parseInt(ev.dataTransfer.getData('billboard'), 10);
              onSlotMoved(fromIndex, i);
            }}
            onDragStart={ev => {
              const parent = findParentByClass(ev.target as any, 'billboard');

              if (parent) {
                ev.dataTransfer.setDragImage(parent, 0, 0);
              }

              ev.dataTransfer.setData('billboard', i.toString());
            }}
            key={billboard.contentId + i.toString()}
            title={billboard.namePreview || ''}
            tileImg={rewriteImageUrl(billboard.tileImage.url)}
            backgroundImg={rewriteImageUrl(billboard.backgroundImage.url)}
            badgeData={billboard.badge || {text: '', backgroundColor: '', sponsoredText: ''}}
            changeHandler={(badge: ISlotBadge) => onSlotChange(i, badge)}
          />
        ))}
        {!readonly &&
          emptySlots.map(i => (
            <AddBox key={i} title='Slot' height='32.9875rem' onClick={() => searchHandler(billboards.length)} />
          ))}
      </Grid>
    </Stack>
  );
};
