import * as React from 'react';

import {ISelectOption, Select} from '@pluto-tv/assemble';
import {useLazySearchByNameQuery} from 'features/carouselConfigs/carouselConfigsApi';
import useSearchCarouselData from 'components/hooks/carousels/useServiceSearchCarousel';
import {TSortDirection} from 'models/generic';

const INIT_PARAMS = {
  sortBy: 'name',
  sortDirection: 'dsc' as TSortDirection,
  limit: 50,
  offset: 0,
};
interface ICarouselConfigAutocompleteProps {
  onSelect: (value?: ISelectOption) => void;
  activeRegion: string;
  disabledIds?: string[];
}

const CarouselConfigAutocomplete = ({
  onSelect,
  activeRegion,
  disabledIds,
}: ICarouselConfigAutocompleteProps): React.ReactElement => {
  const {search: searchCarousel} = useSearchCarouselData(useLazySearchByNameQuery as any);
  const [carouselValue, setCarouselValue] = React.useState<ISelectOption | null>(null);

  const handleChange = (value?: ISelectOption) => {
    setCarouselValue(value || null);
    onSelect(value);
  };

  const handleSearch = async (search: string): Promise<ISelectOption[]> => {
    if (!activeRegion || !search) {
      return [];
    }

    try {
      const response = await searchCarousel({...INIT_PARAMS, name: search, region: [activeRegion]});

      if (!response.totalCount || !response.data.length) {
        return [
          {
            label: 'No Results Found',
            value: '',
            disabled: true,
          },
        ];
      }
      return (
        response?.data.map(config => ({
          label: `${config.name}${config.activeRegion ? ' (' + config.activeRegion[0].toUpperCase() + ')' : ''}`,
          value: config.id,
          disabled: disabledIds?.includes(config.id),
          // Replicate what service-publish does to set this value
          apiService: config.apis[0].apiService || '',
        })) || []
      );
    } catch (error) {
      return [
        {
          label: 'No Results Found',
          value: '',
          disabled: true,
        },
      ];
    }
  };

  return (
    <Select
      clearable={true}
      predicate='value'
      placeholder='Search for carousel'
      searchPlaceholder='Search for carousel'
      searchable={true}
      sortField='label'
      value={{label: carouselValue?.label || '', value: carouselValue?.value || ''}}
      onSearch={handleSearch}
      options={[]}
      autoComplete={true}
      onChange={handleChange}
      id='carouselConfig'
    />
  );
};

CarouselConfigAutocomplete.displayName = 'CarouselConfigAutocomplete';
export default CarouselConfigAutocomplete;
