import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Cover,
  Heading,
  Popover,
  Spinner,
  Stack,
  Template,
  Toast,
  trimModel,
  useValidateForm,
} from '@pluto-tv/assemble';
import {useFindByIdQuery, useDeleteMutation, useUpdateMutation} from 'features/carouselConfigs/carouselConfigsApi';
import {carouselConfigDetailsValidator} from '../validators';
import {useAppPermissions} from 'app/permissions';
import ConfirmRouteChange from 'components/confirmRouteChange';
import programmingRoutes from 'routes/programming.routes';
import {ICarouselConfig} from 'models/carouselConfigs';
import DeleteConfirmation from 'components/deleteConfirmation';
import CarouselDetails from './details';
import CrudError from 'components/crudError';
import carouselRoutes from 'routes/programming.routes';

export type SourceServiceModel = Pick<Partial<ICarouselConfig>, 'apis' | 'apiService' | 'apiUrl'>;

export default (): JSX.Element => {
  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const [isSaving, setIsSaving] = React.useState(false);
  const [isLeaving, setIsLeaving] = React.useState(false);

  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [deleteCarousel] = useDeleteMutation();
  const [updateCarousel] = useUpdateMutation();

  const [sourceModelHaschanged, setSourceModelHasChanged] = React.useState(false);

  const {
    data: carouselConfigData,
    isError: isCarouselError,
    error: carouselError,
    isLoading: isCarouselFetching,
  } = useFindByIdQuery(id, {
    refetchOnMountOrArgChange: true,
  });

  const {ableTo} = useAppPermissions();

  const canDelete = ableTo('CAROUSEL_DELETE');
  const canEdit = ableTo('CAROUSEL_EDIT');

  const {
    form,
    model,
    onBlur,
    onChange,
    pristineModel,
    setFields,
    setModel,
    dirtyFields,
    state: formState,
  } = useValidateForm<ICarouselConfig>(carouselConfigDetailsValidator, 'immediate');

  const [sourceServiceModel, setSourceServiceModelNative] = React.useState<SourceServiceModel>({
    apis: [],
    apiService: '',
    apiUrl: '',
  });

  const setSourceServiceModel = React.useCallback(
    (model: SourceServiceModel) => {
      setSourceServiceModelNative(prev => ({...prev, ...model}));
      setSourceModelHasChanged(true);
    },
    [setSourceServiceModelNative],
  );

  React.useEffect(() => {
    setSourceServiceModel({
      apis: pristineModel.apis,
      apiService: pristineModel.apiService,
      apiUrl: pristineModel.apiUrl,
    });
    setSourceModelHasChanged(false);
  }, [pristineModel, setSourceServiceModel]);

  React.useEffect(() => {
    setModel(carouselConfigData as Partial<ICarouselConfig>);
  }, [carouselConfigData, setModel]);

  React.useEffect(() => {
    if (isLeaving) {
      history.push(programmingRoutes.paths.carouselListPage);
    }
  }, [isLeaving, history]);

  const cancelHandler = () => {
    setIsLeaving(true);
  };

  const toggleDeleteOpen = React.useCallback(() => {
    setDeleteOpen(prev => !prev);
  }, []);

  const deleteHandler = async () => {
    try {
      await deleteCarousel(id).unwrap();
      Toast.success('Success', 'Carousel was deleted successfuly.');
      history.push(carouselRoutes.paths.carouselListPage); // Replace with your target page path
    } catch (error) {
      Toast.error('There were some errors while deleting the Carousel. Plase try again.');
    }
  };

  const saveData = async () => {
    if (!formState.isValid) return;
    setIsSaving(true);
    try {
      // Trim model before saving
      await updateCarousel(
        trimModel({
          ...model,
          ...sourceServiceModel,
          modifier: 'cms_tooling',
          source: undefined,
        }),
      ).unwrap();
      Toast.success('Success', 'Carousel was saved successfuly.');
      setIsSaving(false);
    } catch (error) {
      Toast.error('There were some errors while saving the Carousel. Plase try again.');
      setIsSaving(false);
    }
  };

  if (isCarouselFetching) {
    return (
      <Box fullHeight={true}>
        <Spinner id='carouselDetailsSpinner' center={true} size='xlarge' />
      </Box>
    );
  }

  if (isCarouselError) {
    return <CrudError error={carouselError} />;
  }

  return (
    <>
      <ConfirmRouteChange
        when={!isLeaving ? formState.isDirty || sourceModelHaschanged : false}
        onSave={saveData}
        isValid={formState.isValid}
      />
      <Cover
        scrolling={true}
        gutter='large'
        coverTemplateHeight='100%'
        paddingX={{mobile: 'medium', wide: 'large'}}
        paddingTop={{mobile: 'medium', wide: 'large'}}
      >
        <Template label='header'>
          <Stack space='medium'>
            <Cluster growNthChild={1} justify='space-between' align='center' space='medium' wrap={false}>
              <Stack space='xxsmall'>
                <Heading level='h1' truncate={true} truncateBackgroundHover='shadow'>
                  {pristineModel.name}
                </Heading>
                <Cluster space='xxsmall' wrap={false} fullWidth={true} id='activeRegion'>
                  <Heading level='h5' color='secondary' whiteSpace='nowrap'>
                    Active Region:
                  </Heading>
                  <Heading
                    level='h5'
                    truncate={true}
                    truncateBackgroundHover='shadow'
                    color='primary'
                    id='activeRegion'
                  >
                    {pristineModel.activeRegion?.length
                      ? pristineModel.activeRegion.length === 1
                        ? pristineModel.activeRegion[0].toUpperCase()
                        : pristineModel.activeRegion.join(',')
                      : 'ALL'}
                  </Heading>
                </Cluster>
              </Stack>
              <Cluster space='small' align='center' wrap={false}>
                <Popover
                  manualTrigger={true}
                  permission={canDelete ? '' : 'hidden'}
                  visible={deleteOpen}
                  onClickOutside={() => setDeleteOpen(false)}
                  allowedPlacements={['bottom-end']}
                  maxWidth='22rem'
                >
                  <Template label='trigger'>
                    <Button
                      id='deleteButton'
                      type='delete'
                      onClick={() => toggleDeleteOpen()}
                      permission={canDelete ? '' : 'hidden'}
                    >
                      Delete
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <DeleteConfirmation
                      message={`Are you sure you want to delete ${pristineModel.name}?`}
                      cancelButtonFunction={() => toggleDeleteOpen()}
                      proceedButtonFunction={() => deleteHandler()}
                    />
                  </Template>
                </Popover>
              </Cluster>
            </Cluster>
          </Stack>
        </Template>
        <Template label='cover'>
          <CarouselDetails
            form={form}
            model={model}
            onBlur={onBlur}
            onChange={onChange}
            pristineModel={pristineModel}
            setFields={setFields}
            setModel={setModel}
            dirtyFields={dirtyFields}
            sourceServiceModel={sourceServiceModel}
            setSourceServiceModel={setSourceServiceModel}
          />
        </Template>
        <Template label='footer'>
          <Box background='onyx' paddingX='small' paddingY='small' marginX={{mobile: 'none', wide: 'largeNegative'}}>
            <Cluster justify='space-between'>
              <div></div>
              <Cluster space='xxxsmall'>
                <Button ghost={true} onClick={cancelHandler} id='discard' permission={canEdit ? '' : 'hidden'}>
                  Discard
                </Button>
                <Button
                  type='primary'
                  state={
                    (!formState.isValid || !formState.isDirty) && !sourceModelHaschanged
                      ? 'disabled'
                      : isSaving
                      ? 'thinking'
                      : ''
                  }
                  onClick={saveData}
                  id='save'
                  permission={canEdit ? '' : 'hidden'}
                >
                  Save Changes
                </Button>
              </Cluster>
            </Cluster>
          </Box>
        </Template>
      </Cover>
    </>
  );
};
