import * as React from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {
  Box,
  Cluster,
  Copy,
  ContentBoxes,
  ContentBox,
  ContentBoxColumn,
  DateTime,
  FormItem,
  Grid,
  Heading,
  Help,
  Icon,
  Label,
  Paragraph,
  Select,
  Spinner,
  Stack,
  Textarea,
  TextInput,
  Toggle,
  secondsToHms,
  Button,
  Cover,
  Template,
} from '@pluto-tv/assemble';
import {orderBy} from 'lodash-es';

import {useFindQuery as useFindCategoriesQuery} from 'features/categories/categoriesApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {useFindQuery as useFindRatingDescriptorsQuery} from 'features/ratingDescriptors/ratingDescriptorsApi';
import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';

import CrudError from 'components/crudError';
import PlaylistPlayer from 'components/playlistPlayer';

import {IAgeRange} from 'models/episodes';
import {useUserModelRatings} from 'helpers/useUserRatings';

import contentRoutes from 'routes/content.routes';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';

export default ({model, setFields, onBlur, onChange, form, pristineModel}: INestedEpisodeProps): JSX.Element => {
  const {editPermission} = useEpisodePermissions(pristineModel);
  const history = useHistory();
  const params: {id: string; episodeId?: string} = useParams();

  const {
    contentRatings,
    isError: isErrorContentRatings,
    isFetching: isFetchingContentRatings,
  } = useUserModelRatings(model);

  const {data: categories, isFetching: isFetchingCategories, isError: isErrorCategories} = useFindCategoriesQuery();
  const {data: languages, isFetching: isFetchingLanguages, isError: isErrorLanguages} = useFindLanguagesQuery();
  const {
    data: ratingDescriptors,
    isFetching: isFetchingRatingDescriptors,
    isError: isErrorRatingDescriptors,
  } = useFindRatingDescriptorsQuery();

  const {data: genres, isFetching: isFetchingGenres, isError: isErrorGenres} = useFindGenresQuery();

  React.useEffect(() => {
    onBlur('season', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [model]);

  if (isErrorCategories || isErrorLanguages || isErrorContentRatings || isErrorRatingDescriptors || isErrorGenres) {
    return <CrudError id='crud-error' error='Error loading page data' />;
  }

  if (
    isFetchingCategories ||
    isFetchingLanguages ||
    isFetchingContentRatings ||
    isFetchingRatingDescriptors ||
    isFetchingGenres
  ) {
    return (
      <Box fullHeight={true}>
        <Spinner id='episodes-details-fetching-spinner' center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='columns'>
      <ContentBoxColumn>
        <ContentBox title='About'>
          <Stack space='medium'>
            <FormItem {...form.name} onBlur={() => onBlur('name')} permission={editPermission}>
              <TextInput onChange={value => onChange('name', value)} value={model.name} id='name' />
            </FormItem>
            <FormItem {...form.displayName} onBlur={() => onBlur('displayName')} permission={editPermission}>
              <TextInput
                onChange={value => onChange('displayName', value)}
                value={model.displayName}
                id='displayName'
              />
            </FormItem>
            <Grid rowGap='small' columnGap='xlarge' maxCols={2}>
              <FormItem label='Series Name' child='Paragraph'>
                <Paragraph id='seriesName'>{model.series?.name}</Paragraph>
              </FormItem>
              <FormItem label='Series Display Name' child='Paragraph'>
                <Paragraph id='seriesDisplayName'>{model.series?.displayName}</Paragraph>
              </FormItem>
            </Grid>
            <Stack space='xxlarge'>
              <Grid columnGap='large' maxCols={2}>
                <FormItem {...form.displayOnGuide} permission={editPermission}>
                  <Select
                    onChange={value => setFields({displayOnGuide: value.value})}
                    value={{label: model.displayOnGuide || '', value: model.displayOnGuide || ''}}
                    id='displayOnGuide'
                    predicate='value'
                    options={[
                      {label: 'Mixed', value: 'mixed'},
                      {label: 'Episode', value: 'episode'},
                      {label: 'Series', value: 'series'},
                    ]}
                  />
                </FormItem>
                <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                  <Stack space='xxsmall'>
                    <Help state='info'>
                      Mixed: Guide will display: &apos;
                      <span style={{color: '#60B2F3'}}>
                        {model.series?.displayName || model.series?.name}: {model.displayName || model.name}
                      </span>
                      &apos;
                    </Help>
                    <Help state='info'>
                      Episode: Guide will display: &apos;
                      <span style={{color: '#60B2F3'}}>{model.displayName || model.name}</span>&apos;
                    </Help>
                    <Help state='info'>
                      Series: Guide will display: &apos;
                      <span style={{color: '#60B2F3'}}>{model.series?.displayName || model.series?.name}</span>
                      &apos;
                    </Help>
                  </Stack>
                </Icon>
              </Grid>
              <FormItem {...form.summary} onBlur={() => onBlur('summary')} permission={editPermission}>
                <Textarea onChange={value => onChange('summary', value)} value={model.summary} id='summary' />
              </FormItem>
            </Stack>
            <FormItem {...form.description} onBlur={() => onBlur('description')} permission={editPermission}>
              <Textarea
                onChange={value => onChange('description', value)}
                value={model.description}
                id='description'
                minHeight='6.25rem'
              />
            </FormItem>
            <Grid rowGap='small' columnGap='xlarge'>
              <FormItem {...form.season} permission={editPermission}>
                <Select
                  onChange={value => setFields({season: +value.label})}
                  value={{label: model.season?.toString() || ''}}
                  id='season'
                  options={
                    (model.series?.seasons || [])
                      .sort((a, b) => a.number - b.number)
                      .map(s => ({label: s.number.toString()})) || [{label: '1'}]
                  }
                />
              </FormItem>
              <FormItem {...form.number} permission={editPermission}>
                <TextInput
                  id='episodeNumber'
                  type='number'
                  value={model.number}
                  onChange={value => onChange('number', value)}
                />
              </FormItem>
              <FormItem {...form.rating} permission={editPermission}>
                <Select
                  placeholder='Please select rating'
                  onChange={value => setFields({rating: value.label})}
                  value={{label: model.rating || ''}}
                  id='rating'
                  sortField='weight'
                  options={contentRatings}
                />
              </FormItem>
              <FormItem child='Paragraph' label='Age Range'>
                <span>
                  {model.ageRange?.map((ar: IAgeRange) => (
                    <Paragraph key={ar.id}>{ar.displayName}</Paragraph>
                  ))}
                </span>
              </FormItem>
            </Grid>
            <FormItem {...form.ratingDescriptors} permission={editPermission}>
              <Select
                multiselect
                clearable
                onChange={value =>
                  setFields({ratingDescriptors: value?.sort((a, b) => (a.label > b.label ? 1 : -1)).map(v => v.value)})
                }
                value={model.ratingDescriptors?.map(d => ({
                  label: ratingDescriptors?.find(rd => rd.slug == d)?.displayName || '',
                  value: d,
                }))}
                id='ratingDescriptors'
                predicate='value'
                options={(ratingDescriptors || [])
                  .filter(rd => rd.region.toLowerCase() === model.activeRegion)
                  .map(rd => ({
                    label: `${rd.displayName} ${rd.label ? `(${rd.label})` : ''}`,
                    value: rd.slug,
                  }))}
              />
            </FormItem>
            <Grid rowGap='small' columnGap='xlarge'>
              <FormItem {...form.genre} permission={editPermission}>
                <Select
                  onChange={value => setFields({genre: value.label, subGenre: undefined})}
                  value={{label: model.genre || ''}}
                  id='genre'
                  options={orderBy(
                    (genres || []).map(g => ({label: g.genre})),
                    'label',
                  )}
                  searchable={true}
                  searchPlaceholder='Search for genre'
                  onSearch={val =>
                    orderBy(
                      (genres || [])
                        .filter(g => g.genre.toLowerCase().indexOf(val.toLowerCase()) > -1)
                        .map(g => ({label: g.genre}), 'label'),
                    ) || []
                  }
                />
              </FormItem>
              <FormItem {...form.subGenre} permission={editPermission}>
                <Select
                  onChange={value => setFields({subGenre: value.label})}
                  value={{label: model.subGenre || ''}}
                  id='subGenre'
                  options={orderBy(
                    ((model.genre && genres?.find(g => g.genre === model.genre)?.subGenres) || []).map(sg => ({
                      label: sg,
                    })),
                    'label',
                  )}
                  searchable={true}
                  searchPlaceholder='Search for sub-genre'
                  onSearch={val =>
                    orderBy(
                      ((model.genre && genres?.find(g => g.genre === model.genre)?.subGenres) || [])
                        .filter(sg => sg.toLowerCase().indexOf(val.toLowerCase()) > -1)
                        .map(sg => ({label: sg}), 'label'),
                    ) || []
                  }
                />
              </FormItem>
              <FormItem {...form.category} permission={editPermission}>
                <Select
                  placeholder='Please select category'
                  onChange={value => setFields({category: value?.label, subCategory: undefined})}
                  value={{label: model.category || ''}}
                  id='category'
                  options={orderBy(
                    (categories || []).map(category => ({label: category.category})),
                    'label',
                  )}
                  predicate='label'
                  searchable={true}
                  searchPlaceholder='Search for category'
                  onSearch={val =>
                    orderBy(
                      (categories || [])
                        .filter(item => item.category.toLowerCase().indexOf(val.toLowerCase()) > -1)
                        .map(item => ({label: item.category}), 'label'),
                    ) || []
                  }
                />
              </FormItem>
              <FormItem {...form.subCategory} permission={editPermission}>
                <Select
                  placeholder='Please select sub-category'
                  onChange={value => setFields({subCategory: value.label})}
                  value={{label: model.subCategory || ''}}
                  predicate='label'
                  id='subCategory'
                  options={orderBy(
                    ((model.category && categories?.find(g => g.category === model.category)?.subCategories) || []).map(
                      sc => ({label: sc}),
                    ),
                    'label',
                  )}
                  searchable={true}
                  searchPlaceholder='Search for sub-category'
                  onSearch={val =>
                    orderBy(
                      ((model.category && categories?.find(g => g.category === model.category)?.subCategories) || [])
                        .filter(item => item.toLowerCase().indexOf(val.toLowerCase()) > -1)
                        .map(item => ({label: item}), 'label'),
                    ) || []
                  }
                />
              </FormItem>
            </Grid>
            <FormItem
              {...form.plutoTvOO}
              permission={editPermission}
              child='Toggle'
              helpText='Important: If selected, episode belongs to PlutoTV O&O'
              helpTextColor='info'
            >
              <Toggle
                id='plutoTvOO'
                label='Yes'
                value={model.plutoTvOO}
                onChange={value => setFields({plutoTvOO: value})}
              />
            </FormItem>
            <FormItem {...form.metadataLanguage} permission={editPermission}>
              <Select
                onChange={value => setFields({metadataLanguage: value.value})}
                value={{label: model.metadataLanguage || '', value: model.metadataLanguage}}
                id='metadataLanguage'
                predicate='value'
                searchable={true}
                searchPlaceholder='Search for language'
                onSearch={val =>
                  orderBy(
                    (languages || [])
                      .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                      .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                  ) || []
                }
                options={orderBy(
                  (languages || []).map(language => ({label: language.name, value: language.iso639_1})),
                  'label',
                )}
              />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
      <ContentBoxColumn>
        <ContentBox title='Quick View'>
          <Stack space='xlarge'>
            {(model.sources || []).length === 0 ? (
              <Box height='21.875rem'>
                <Cover center={true}>
                  <Template label='cover'>
                    <Stack space='large' alignItems='center'>
                      <Heading level='h3'>There are no clips in this episode yet.</Heading>
                      <Button
                        type='primary'
                        permission={editPermission}
                        onClick={() =>
                          history.push(
                            params.episodeId && params.id
                              ? contentRoutes.paths.seriesEditEpisodesVideoPage
                                  .replace(':id', params.id)
                                  .replace(':episodeId', params.episodeId) + '?addClip=true'
                              : contentRoutes.paths.episodeEditVideoPage.replace(':id', params.id!) + '?addClip=true',
                          )
                        }
                      >
                        + Add Clip
                      </Button>
                    </Stack>
                  </Template>
                </Cover>
              </Box>
            ) : (
              <PlaylistPlayer height='21.875rem' clips={model.sources} id='preview' />
            )}
            <Cluster align='center' justify='center' space='xxxxxxlarge'>
              <Stack space='xsmall'>
                <Label>Episode Duration</Label>
                <Heading id='episodeDuration' level='h4'>
                  {secondsToHms(model.duration || 0)}
                </Heading>
              </Stack>
            </Cluster>
          </Stack>
        </ContentBox>
        <ContentBox title='Episode Information'>
          <Grid rowGap='small' columnGap='xlarge'>
            <FormItem {...form.premiere} permission={editPermission}>
              <DateTime
                id='premiereDateTime'
                time={true}
                value={model.premiere ? new Date(model.premiere || '') : undefined}
                onChange={val =>
                  setFields({
                    premiere: val ? new Date(val as Date).toISOString() : undefined,
                  })
                }
              />
            </FormItem>
            <FormItem label='Next Air Date' child='Copy'>
              <Copy
                text={model.nextTimeline?.start ? new Date(model.nextTimeline?.start || '').toLocaleString() : 'N/A'}
                toCopy={model.nextTimeline?.start ? new Date(model.nextTimeline?.start || '').toLocaleString() : 'N/A'}
              />
            </FormItem>
            <FormItem label='Created At' child='Copy'>
              <Copy
                text={new Date(model.createdAt!).toLocaleString()}
                toCopy={new Date(model.createdAt!).toLocaleString()}
              />
            </FormItem>
            <FormItem label='Updated At' child='Copy'>
              <Copy
                text={model.updatedAt ? new Date(model.updatedAt || '').toLocaleString() : 'N/A'}
                toCopy={model.updatedAt ? new Date(model.updatedAt || '').toLocaleString() : 'N/A'}
              />
            </FormItem>
            <FormItem label='UUID' child='Copy'>
              <Copy text={model.contentUUID || 'N/A'} toCopy={model.contentUUID || 'N/A'} />
            </FormItem>
            <FormItem label='Episode ID' child='Copy'>
              <Copy text={model.id} toCopy={model.id} />
            </FormItem>
          </Grid>
        </ContentBox>
        <ContentBox title='Additional Details'>
          <Stack space='medium'>
            <FormItem {...form.notes} permission={editPermission}>
              <Textarea onChange={value => onChange('notes', value)} value={model.notes} id='notes' />
            </FormItem>
          </Stack>
        </ContentBox>
      </ContentBoxColumn>
    </ContentBoxes>
  );
};
