import * as React from 'react';
import {
  Button,
  Cluster,
  Cover,
  Divider,
  FormItem,
  Heading,
  Icon,
  Template,
  TextInput,
  Stack,
  useValidateForm,
  Select,
  ISelectOption,
} from '@pluto-tv/assemble';
import {ICarouselConfigSearch} from 'models/carouselConfigs';
import {carouselConfigSearchValidator} from 'views/programming/hubManager/carousels/validators';
import {useCarouseSearchlLazyLoadProvider} from 'components/carouselList/providers/CarouselSearchLazyLoadProvider';
import {useUserRegions} from 'helpers/useUserRegions';

interface ICarouselSearchProps {
  onSearch: (search: Partial<ICarouselConfigSearch>) => void;
  onClear: () => void;
  setIsExpanded: (value: boolean) => void;
  favoriteSearch?: React.ReactNode;
}

export const carouselConfigTitleId = 'nameFilter';

const CarouselSearch = ({
  onSearch,
  onClear,
  setIsExpanded,
  favoriteSearch,
}: ICarouselSearchProps): React.ReactElement => {
  const {isLoading} = useCarouseSearchlLazyLoadProvider();
  const {
    model: searchModel,
    onChange: searchOnChange,
    setFields: searchSetFields,
    form: searchForm,
    onBlur: searchOnBlur,
    reset: searchReset,
    state: searchFormState,
  } = useValidateForm<ICarouselConfigSearch>(carouselConfigSearchValidator, 'immediate');
  const {activeRegions} = useUserRegions();

  const handleSearch = async () => {
    if (!searchModel.name) return;

    onSearch({
      ...searchModel,
      region: searchModel.region || undefined,
      name: searchModel.name,
    });
  };

  const handleClear = async () => {
    searchReset();
    onClear();
  };

  return (
    <Cover scrolling={true} gutter='medium'>
      <Template label='header'>
        <Stack space='medium'>
          <Cluster align='center' justify='space-between'>
            <Icon icon='tune' space='small' size='large' iconAlign='center'>
              <Heading level='h4'>Search Filters</Heading>
            </Icon>
            <Icon id='closeFilters' icon='collapseleft' size='large' onClick={() => setIsExpanded(false)} />
          </Cluster>
          {favoriteSearch && <>{favoriteSearch}</>}
          <Divider color='graphite' />
        </Stack>
      </Template>
      <Template label='cover'>
        <form
          onSubmit={ev => {
            ev.preventDefault();
            handleSearch();
          }}
        >
          <Stack space='small'>
            <input type='submit' style={{display: 'none'}} />
            <Stack space='xlarge'>
              <FormItem
                {...searchForm.name}
                onBlur={() => {
                  searchOnBlur('name');
                }}
              >
                <TextInput
                  id={carouselConfigTitleId}
                  clearable={true}
                  placeholder='Title'
                  value={searchModel.name}
                  onChange={val => {
                    searchOnChange('name', val);
                  }}
                />
              </FormItem>
              <FormItem
                {...searchForm.region}
                onBlur={() => {
                  searchOnBlur('region');
                }}
              >
                <Select
                  clearable={true}
                  multiselect={true}
                  placeholder='Select Active Region'
                  value={searchModel.region?.map(val => ({
                    label: '',
                    value: val,
                  }))}
                  onChange={val => {
                    searchSetFields({
                      region: (val as ISelectOption[])?.map(ar => ar.value as string),
                    });
                  }}
                  options={activeRegions.map(ar => ({
                    label: `${ar.name} (${ar.code})`,
                    value: ar.code.toUpperCase(),
                  }))}
                  predicate='value'
                />
              </FormItem>
            </Stack>
          </Stack>
        </form>
      </Template>

      <Template label='footer'>
        <Cluster justify='space-between'>
          <div></div>
          <Cluster space='small'>
            <Button id='clearButton' ghost={true} onClick={handleClear} state={isLoading ? 'disabled' : ''}>
              Clear
            </Button>
            <Button
              id='searchButton'
              type='primary'
              onClick={handleSearch}
              state={isLoading ? 'thinking' : !searchFormState.isValid ? 'disabled' : ''}
            >
              Search
            </Button>
          </Cluster>
        </Cluster>
      </Template>
    </Cover>
  );
};

export default CarouselSearch;
