import * as React from 'react';

import {Box, Cover, Icon, Paragraph, Stack, Template, TSize} from '@pluto-tv/assemble';

export interface IAddBoxProps {
  title: string;
  height: TSize;
  onClick?(): void;
}

const AddBox = React.memo(({title, height, onClick}: IAddBoxProps) => {
  return (
    <Box
      padding='small'
      borderRadius='0.5rem'
      borderSize='0.125rem'
      borderColor='white'
      borderStyle='dashed'
      borderTop={true}
      borderRight={true}
      borderBottom={true}
      borderLeft={true}
      height={height}
      background='slate'
      backgroundHover='cavern'
      onClick={onClick}
      id='addRunLimitBox'
    >
      <Cover center={true}>
        <Template label='cover'>
          <Stack space='small' alignItems='center'>
            <Paragraph>Add {title}</Paragraph>
            <Icon icon='add' size='xlarge' color='primary' verticalAlign='bottom' onClick={onClick} />
          </Stack>
        </Template>
      </Cover>
    </Box>
  );
});

AddBox.displayName = 'AddBox';
export default AddBox;
