import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Cluster,
  Click,
  ContentBoxes,
  ContentBox,
  Cover,
  Expand,
  Heading,
  Icon,
  Paragraph,
  Popover,
  Sidebar,
  Stack,
  Template,
  Toast,
  trimModel,
  useValidateForm,
} from '@pluto-tv/assemble';
import {useAppPermissions} from 'app/permissions';
import featuredGroupRoutes from 'routes/programming.routes';
import {useInsertMutation, usePublishMutation, useDeleteMutation} from 'features/featuredGroups/featuredGroupsApi';
import {IFeaturedGroup, IFeaturedGroupsSearchValues} from 'models/featuredGroup';
import {featuredGroupCreateValidator} from '../../views/programming/featuredGroups/validators';
import {useUserRegions} from 'helpers/useUserRegions';
import DeleteConfirmation from 'components/deleteConfirmation';
import FeaturedGroupsTable from 'components/featuredGroupList/components/FeaturedGroupsTable';
import FeaturedGroupsPagination from 'components/featuredGroupList/components/FeaturedGroupsPagination';
import {useFeaturedGroupsSearchQuery} from 'components/featuredGroupList/providers/FeaturedGroupsSearchQueryProvider';
import {IFeaturedGroupQuery} from 'features/featuredGroups/featuredGroupsApi';
import FeaturedGroupsSearch from './components/FeaturedGroupsSearch';
import {getCurrentMonth, getCurrentYear, monthNames} from 'components/featuredGroupList/utils/dateUtils';
import LaunchCards from 'components/featuredGroupList/components/LaunchCards';
import {createDate} from 'components/featuredGroupList/utils/dateUtils';
import {featuredGroupsTableId} from 'components/featuredGroupList/components/FeaturedGroupsTable';
import useAllGroupsData from 'components/featuredGroupList/hooks/useAllGroupsData';
import useValidateRows from 'components/featuredGroupList/hooks/useValidateRows';
import usePublishedUpcomingData from 'components/featuredGroupList/hooks/usePublishedUpcomingData';
import useLocalStorage from 'helpers/useLocalStorage';
import Confirmation from 'components/saveConfirmation/confirmation';
import FeaturedGroupCreateDialog from './components/FeaturedGroupCreateDialog';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

export const ACTIVE_REGION_LOCALSTORAGE_KEY = 'activeRegionSelected';

export const TABLE_COLUMN_NAME = {
  Day: 'day',
  'Start Date': 'start',
  'Group Name': 'name',
  '# of Channels': 'numberOfChannels',
  'Active Region': 'activeRegion',
  Published: 'published',
} as const;

export interface SearchState {
  page: number;
  rowsPerPage: 25 | 50 | 75 | 100;
  searchObj: Omit<IFeaturedGroupQuery, 'offset' | 'limit' | 'sort'>;
  sort: {col: keyof typeof TABLE_COLUMN_NAME; dir: 'asc' | 'dsc'}[];
}

const searchReducer = (prevState: SearchState, data: Partial<SearchState>) => {
  return {
    ...prevState,
    ...data,
  };
};

const createSearchQuery = (search: Partial<IFeaturedGroupsSearchValues>): Partial<IFeaturedGroupQuery> => {
  const searchQuery = {};

  const mapKeys = [
    {key: 'groupName', value: 'groupName'},
    {key: 'activeRegions', value: 'activeRegion'},
  ];

  mapKeys.forEach(keyValue => {
    if (search[keyValue.key]) {
      searchQuery[keyValue.value] = search[keyValue.key];
    }
  });

  if (search.channels) {
    searchQuery['channelIds'] = search.channels.value;
  }

  if (search.activeRegion) {
    searchQuery['activeRegion'] = search.activeRegion.toLowerCase();
  }

  if (search.published) {
    searchQuery['published'] = search.published === 'Yes';
  }

  if (search.monthYear) {
    searchQuery['startDate'] = createDate(search.monthYear.month, search.monthYear.year).toISOString();
    searchQuery['startEndDate'] = createDate(search.monthYear.month, search.monthYear.year, 'end').toISOString();
  }

  return searchQuery;
};

export interface IFeaturedGroupListProps {
  addNewFeaturedGroup?: boolean;
  deleteFeaturedGroup?: boolean;
  deviceUpdates?: boolean;
  showFavoriteSearch?: boolean;
  inModal?: boolean;
  onSelect?: () => void;
  nameTarget?: React.HTMLAttributeAnchorTarget;
  isSearchExpanded?: boolean;
}

const DEFAULT_SEARCH: SearchState = {
  page: 0,
  rowsPerPage: 50,
  searchObj: {
    startDate: createDate(getCurrentMonth(), getCurrentYear()).toISOString(),
    startEndDate: createDate(getCurrentMonth(), getCurrentYear(), 'end').toISOString(),
  },
  sort: [
    {col: 'Start Date', dir: 'asc'},
    {col: 'Published', dir: 'dsc'},
  ],
};

const FeaturedGroupList = React.memo(
  ({
    addNewFeaturedGroup = true,
    deleteFeaturedGroup = true,
    inModal = false,
    isSearchExpanded = true,
  }: IFeaturedGroupListProps) => {
    const history = useHistory();
    const {activeRegions} = useUserRegions();
    const {ableTo} = useAppPermissions();

    const canDelete = ableTo('FEATURED_GROUPS_DELETE');
    const canCreate = ableTo('FEATURED_GROUPS_CREATE');

    const [isDeleteMultipleOpen, setIsDeleteMultipleOpen] = React.useState(false);
    const [searchExpanded, setSearchExpanded] = React.useState(isSearchExpanded);
    const [selectedRows, setSelectedRows] = React.useState<IFeaturedGroup[]>([]);
    const [isCreating, setIsCreating] = React.useState(false);
    const [createOpen, setCreateOpen] = React.useState(false);
    const [isSearchActive, setIsSearchActive] = React.useState(true);
    const [warnings, setWarnings] = React.useState<string[]>([]);
    const [featuredGroupBeingPublished, setFeaturedGroupBeingPublished] = React.useState<IFeaturedGroup | null>(null);
    const [isPublishing, setIsPublishing] = React.useState<boolean>(false);

    useToggleSearchBarOnSlash(setSearchExpanded, searchExpanded);

    const [activeRegionSelected, setActiveRegionSelected] = useLocalStorage<string>(
      ACTIVE_REGION_LOCALSTORAGE_KEY,
      activeRegions?.length === 1 ? activeRegions[0].code : '',
    );

    const [insertFeaturedGroup] = useInsertMutation();
    const [deleteFeaturedGroups] = useDeleteMutation();
    const [publishFeaturedGroup] = usePublishMutation();
    const {
      isFetching,
      error,
      data,
      currentData: featuredGroupData,
      isError,
      fetch: fetchFeaturedGroups,
    } = useFeaturedGroupsSearchQuery();

    const [searchQuery, searchQueryDispatch] = React.useReducer<React.Reducer<SearchState, Partial<SearchState>>>(
      searchReducer,
      DEFAULT_SEARCH,
    );

    const {mappedData: validatorData} = useAllGroupsData(searchQuery);
    const {featuredGroups: publishedUpcomingData, isFetching: isUpcomingDataFetching} = usePublishedUpcomingData({
      activeRegion: activeRegionSelected || searchQuery?.searchObj?.activeRegion,
    });
    const {validate: validateRow, reset: resetValidators} = useValidateRows(
      searchQuery,
      validatorData,
      publishedUpcomingData || [],
    );

    const {
      form,
      model,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
      state: formState,
    } = useValidateForm<IFeaturedGroup>(featuredGroupCreateValidator, 'onBlur');

    React.useEffect(() => {
      if (!activeRegions.length) return;
      const {page, rowsPerPage, sort, searchObj} = searchQuery;

      fetchFeaturedGroups({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
        sort: sort.map(s => `${TABLE_COLUMN_NAME[s.col]}:${s.dir}`).join(','),
        activeRegion: activeRegionSelected || activeRegions.map(ar => ar.code.toLowerCase()),
        ...searchObj,
      } as IFeaturedGroupQuery);
    }, [activeRegionSelected, activeRegions, fetchFeaturedGroups, searchQuery]);

    const launchCardsData = React.useMemo(
      () =>
        activeRegionSelected
          ? isUpcomingDataFetching
            ? publishedUpcomingData
            : publishedUpcomingData?.filter(
                data => data.activeRegion.toUpperCase() === activeRegionSelected.toUpperCase(),
              )
          : [],
      [activeRegionSelected, publishedUpcomingData, isUpcomingDataFetching],
    );

    const currentDateFilters: {month: string; year: number} = React.useMemo(() => {
      const date = searchQuery.searchObj.startDate
        ? new Date(searchQuery.searchObj.startDate)
        : DEFAULT_SEARCH.searchObj.startDate
        ? new Date(DEFAULT_SEARCH.searchObj.startDate)
        : new Date();

      return {
        month: monthNames[date.getMonth()],
        year: date.getFullYear(),
      };
    }, [searchQuery.searchObj.startDate]);

    const rows = React.useMemo(() => {
      const dataRows = ((error as {status: number})?.status === 404 ? [] : data?.data) || [];
      const rowsWithState = dataRows.map(row => ({
        ...row,
        state: validateRow(row),
      }));

      // reset validators internal data after each render
      resetValidators();
      return rowsWithState;
    }, [data?.data, error, resetValidators, validateRow]);

    React.useEffect(() => {
      if (isError) {
        Toast.error('There was an error loading the featured groups.');
      }
    }, [isError]);

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      let featuredGroupId: string | undefined;

      try {
        setIsCreating(true);
        const postModel: Partial<IFeaturedGroup> = {
          ...model,
          activeRegion: model.activeRegion?.toUpperCase(),
          start: (model.start as Date).toISOString(),
        };

        const newFeaturedGroup = await insertFeaturedGroup(trimModel(postModel, 'name')).unwrap();
        featuredGroupId = newFeaturedGroup.id!;

        const toastMsg = navigateTo ? (
          'Featured Group Created'
        ) : (
          <Stack space='xxsmall'>
            <Paragraph>Featured Group Created</Paragraph>
            <Click
              underline={true}
              hoverColor='white'
              onClick={() =>
                history.push(
                  featuredGroupRoutes.paths.featuredGroupEditDetailsPage.replace(':id', featuredGroupId as string),
                )
              }
            >
              View Featured Group: {postModel.name}
            </Click>
          </Stack>
        );

        Toast.success('Success', toastMsg, 8000);
        setCreateOpen(false);
      } catch (e) {
        Toast.error('Error', (e as any).data.message);
      } finally {
        setIsCreating(false);
      }

      return featuredGroupId;
    };

    const handleCreateAndEdit = async () => {
      try {
        const featuredGroupId = await handleCreate(true);

        if (featuredGroupId) {
          history.push(
            featuredGroupRoutes.paths.featuredGroupEditDetailsPage.replace(':id', featuredGroupId as string),
          );
        }
      } catch (e) {}
    };

    const handleCancel = () => {
      setCreateOpen(false);
    };

    const handleEdit = (featuredGroupId: string) => {
      history.push(featuredGroupRoutes.paths.featuredGroupEditDetailsPage.replace(':id', featuredGroupId));
    };

    const openCreate = () => {
      resetCreate();
      setCreateOpen(true);
    };

    const handleWarningsModalCancel = (discard: boolean) => {
      if (discard) {
        setFeaturedGroupBeingPublished(null);
      }
      setWarnings([]);
    };

    const handlePublish = async (featuredGroup: IFeaturedGroup, acceptWarnings = false) => {
      setFeaturedGroupBeingPublished(featuredGroup);
      try {
        setIsPublishing(true);
        await publishFeaturedGroup({id: featuredGroup.id, acceptWarnings}).unwrap();
        setIsPublishing(false);
        setWarnings([]);
        setFeaturedGroupBeingPublished(null);
        Toast.success('Success', 'Featured Group Published');
      } catch (error: any) {
        if (error.data?.warnings) {
          setWarnings(error.data.warnings);
        } else {
          const errorMsg = error.data?.message;
          Toast.error('Error', !!errorMsg ? errorMsg : 'Failed to publish featured group. Please try again', 8000);
          setWarnings([]);
        }
      } finally {
        setIsPublishing(false);
      }
    };

    const handleDuplicate = async (clonedRow: IFeaturedGroup) => {
      const newFeaturedGroup: Partial<IFeaturedGroup> = {
        name: `${clonedRow.name} (Copy)`.trim(),
        start: clonedRow.start,
        activeRegion: clonedRow.activeRegion,
        contentItems: clonedRow.contentItems,
      };

      try {
        const response = await insertFeaturedGroup(newFeaturedGroup).unwrap();

        if (response) {
          Toast.success(`The group "${clonedRow.name}" has been duplicated successfully.`);
        }
      } catch {
        Toast.error('Error duplicating group. Please try again.');
      }
    };

    const handleDelete = async (ids: string | string[]) => {
      const idsCount = Array.isArray(ids) && ids.length > 1 ? ids.length : 1;
      try {
        const response: any = await deleteFeaturedGroups({ids});

        if (response.error) {
          return idsCount > 1
            ? Toast.error(`The selected Group(s) failed to delete. Please try again.`)
            : Toast.error('Error deleting featured group. Please try again.');
        } else {
          return idsCount > 1
            ? Toast.success(`${ids.length} groups were deleted successfully.`)
            : Toast.success(`The group was deleted successfully.`);
        }
      } catch (e) {
        return idsCount > 1
          ? Toast.error(`The selected Group(s) failed to delete. Please try again.`)
          : Toast.error('Error deleting featured group. Please try again.');
      }
    };

    const handleMultipleDelete = () => {
      // published groups can not be removed
      const groupsToDelete = selectedRows.filter(row => !row.published).map(row => row.id);
      if (groupsToDelete.length) {
        handleDelete(groupsToDelete);
      }
    };

    const handleSearch = (search: Partial<IFeaturedGroupsSearchValues>) => {
      setIsSearchActive(true);

      if (activeRegionSelected !== search.activeRegion) {
        setActiveRegionSelected(search.activeRegion || '');
      }

      const searchObj = createSearchQuery(search);

      searchQueryDispatch({
        ...searchQuery,
        searchObj,
        page: 0,
      });
    };

    const handleClear = () => {
      setIsSearchActive(false);
      setActiveRegionSelected('');
      searchQueryDispatch(DEFAULT_SEARCH);
    };

    return (
      <>
        {warnings.length > 0 && (
          <Confirmation
            isSaving={isPublishing}
            onCancel={() => handleWarningsModalCancel(false)}
            onConfirm={() => handlePublish(featuredGroupBeingPublished!, true)}
            onDiscard={() => handleWarningsModalCancel(true)}
            warnings={warnings}
          />
        )}
        <Sidebar fullHeight={true}>
          <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={searchExpanded}>
            <Template label='expandable'>
              <Box
                background='pewter'
                paddingY={inModal ? 'none' : 'medium'}
                paddingRight='medium'
                paddingLeft={inModal ? 'none' : 'medium'}
                fullHeight={true}
              >
                <FeaturedGroupsSearch
                  onCollapse={() => {
                    setSearchExpanded(false);
                  }}
                  isExpanded={searchExpanded}
                  onSearch={handleSearch}
                  onClear={handleClear}
                  setIsSearchActive={setIsSearchActive}
                  isSearchActive={isSearchActive}
                  activeRegionSelected={activeRegionSelected || ''}
                />
              </Box>
            </Template>
          </Expand>
          <Cover
            scrolling={true}
            gutter='medium'
            coverTemplateHeight='100%'
            overflow='auto'
            padding={inModal ? 'none' : {mobile: 'medium', wide: 'large'}}
          >
            <Template label='header'>
              <Cluster justify='space-between' align='center' space='medium'>
                <Stack space='xxxsmall'>
                  <Cluster align='end' space='small'>
                    <Box paddingBottom='xxxsmall'>
                      <Heading level='h1'>{`Featured Groups - ${currentDateFilters.month}. ${currentDateFilters.year}`}</Heading>
                    </Box>
                    <Box paddingBottom='xxxsmall'>
                      <Cluster space='xxsmall' align='center'>
                        <Icon
                          id='searchFilter'
                          icon='tune'
                          space='xxxsmall'
                          verticalAlign='bottom'
                          lineHeight='0px'
                          onClick={() => setSearchExpanded(!searchExpanded)}
                        >
                          {isError ? '0' : featuredGroupData?.metadata.totalCount || 0} Items
                        </Icon>
                      </Cluster>
                    </Box>
                  </Cluster>
                  {activeRegionSelected && (
                    <Heading level='h5' truncate={true} truncateBackgroundHover='shadow' color='secondary'>
                      Active Region:&nbsp;&nbsp;
                      <span style={{color: '#FFF'}}>{activeRegionSelected}</span>
                    </Heading>
                  )}
                </Stack>
                <Cluster space='small' align='center'>
                  {addNewFeaturedGroup && (
                    <>
                      <Button
                        id='addFeaturedGroup'
                        type='primary'
                        onClick={() => openCreate()}
                        permission={canCreate ? '' : 'hidden'}
                      >
                        + New Featured Group
                      </Button>
                      <FeaturedGroupCreateDialog
                        activeRegions={activeRegions}
                        createOpen={createOpen}
                        form={form}
                        formState={formState}
                        handleCancel={handleCancel}
                        handleCreate={handleCreate}
                        handleCreateAndEdit={handleCreateAndEdit}
                        isCreating={isCreating}
                        model={model}
                        onBlur={onBlur}
                        onChange={onChange}
                        setFields={setFields}
                      />
                    </>
                  )}
                  {canDelete && deleteFeaturedGroup && (
                    <Popover
                      manualTrigger={true}
                      visible={isDeleteMultipleOpen}
                      onClickOutside={() => setIsDeleteMultipleOpen(false)}
                      allowedPlacements={['bottom-end']}
                      maxWidth='22rem'
                    >
                      <Template label='trigger'>
                        <Button
                          id='deleteGroups'
                          permission={canDelete ? '' : 'hidden'}
                          type='delete'
                          state={selectedRows.filter(row => !row.published).length === 0 ? 'disabled' : ''}
                          onClick={() => setIsDeleteMultipleOpen(true)}
                        >
                          Delete Group(s)
                        </Button>
                      </Template>
                      <Template label='popover'>
                        <DeleteConfirmation
                          message='Are you sure you want to delete selected unpublished Featured Groups?'
                          cancelButtonFunction={() => setIsDeleteMultipleOpen(false)}
                          proceedButtonFunction={() => {
                            handleMultipleDelete();
                            setIsDeleteMultipleOpen(false);
                          }}
                        />
                      </Template>
                    </Popover>
                  )}
                  {!addNewFeaturedGroup && !deleteFeaturedGroup && <Box height='1.7rem'></Box>}
                </Cluster>
              </Cluster>
            </Template>
            <Template label='cover'>
              <Cover gutter='medium' coverTemplateHeight='100%' scrolling={true}>
                <Template label='header'>
                  {activeRegionSelected && (
                    <LaunchCards
                      activeRegionSelected={activeRegionSelected}
                      validatorData={validatorData}
                      handleDuplicate={handleDuplicate}
                      handleDelete={handleDelete}
                      handleEdit={handleEdit}
                      handlePublish={handlePublish}
                      publishedUpcomingData={launchCardsData}
                    />
                  )}
                </Template>
                <Template label='cover'>
                  <ContentBoxes layout='cover'>
                    <Template label='contentBoxesCover'>
                      <ContentBox>
                        <Template label='content'>
                          <FeaturedGroupsTable
                            onEdit={handleEdit}
                            onCopy={handleDuplicate}
                            onDelete={handleDelete}
                            onSelectRows={setSelectedRows}
                            selectedRows={selectedRows}
                            rows={rows}
                            isFetching={isFetching}
                          />
                        </Template>
                      </ContentBox>
                    </Template>
                  </ContentBoxes>
                </Template>
              </Cover>
            </Template>
            <Template label='footer'>
              <Cluster justify='space-between'>
                <div></div>
                <FeaturedGroupsPagination
                  onChangeState={searchQueryDispatch}
                  searchState={searchQuery}
                  onPageChange={() => {
                    document.getElementById(featuredGroupsTableId)?.parentElement?.scrollTo(0, 0);
                  }}
                />
              </Cluster>
            </Template>
          </Cover>
        </Sidebar>
      </>
    );
  },
);

FeaturedGroupList.displayName = 'FeaturedGroupList';
export default FeaturedGroupList;
