import * as React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Box,
  Button,
  Click,
  Cluster,
  Cover,
  Dialog,
  Divider,
  FormItem,
  Heading,
  Help,
  ISimpleEntry,
  Icon,
  Label,
  Paragraph,
  Popover,
  Range,
  Stack,
  TSize,
  Table,
  TdMultilineTruncate,
  Template,
  TextInput,
  Timeline,
  isValEmpty,
  ZOOM_LEVELS,
  secondsToHms,
  hmsToSeconds,
  TIcons,
  Toast,
  summon,
  Spinner,
  ISimpleAdPod,
  ITableCol,
  TZoomLevelsTimeline,
  useUndo,
} from '@pluto-tv/assemble';
import {cloneDeep, debounce, isNumber, shuffle, uniqBy} from 'lodash-es';

import {TableActions} from 'components/tableActions';
import DeleteConfirmation from 'components/deleteConfirmation';
import ClipList from 'components/clipList';
import PlaylistPlayer, {IPlaylistPlayerRef} from 'components/playlistPlayer';

import {isDropFrame, msToTimecode, timecodeToMs} from 'helpers/msToTimecode';
import {IPopover} from 'helpers/popoverInterface';
import {useSearchParams} from 'helpers/useSearchQuery';
import {mapPopulatedEpisodeToEpisode} from 'helpers/mapPopulatedEpisode';

import {IClip} from 'models/clips';
import {IEpisode, IEpisodeSource} from 'models/episodes';
import {popoverActionsProps} from 'helpers/popoverActionProps';

import {calcMarkerOffset, onDropClip, setActive, transformClipToEntry, transformClips} from './workers';
import AdPodForm, {IAdPodValue} from './adPodForm';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';
import {useKeyboardShortcut} from 'views/programming/channel/edit/program/hooks/useKeyboardShortcut';

const css = `
  #timelineContainer {
    margin-right: 1.375rem;
  }
`;

interface IPlaceholder {
  mask: string;
  placeholder: string;
}

interface IMagicWand extends Partial<IEpisode> {
  adPodsDuration: number;
}

const dropFramePlaceholder: IPlaceholder = {
  mask: 'NN:[0-5]N:[0-5]N;NN',
  placeholder: '--:--:--;--',
};

const nonDropFramePlaceholder: IPlaceholder = {
  mask: 'NN:[0-5]N:[0-5]N:NN',
  placeholder: '--:--:--:--',
};

export interface IEpisodeVideoRef {
  getDirtySources(): Set<string>;
  isSourceDirty(id: string): boolean;
  resetDirtySources(): void;
}

const EpisodeVideo = React.forwardRef<IEpisodeVideoRef, INestedEpisodeProps>(
  ({model, pristineModel, setFields, setHasInOutPointWarning}: INestedEpisodeProps, ref) => {
    const {CAN_EDIT, editPermission} = useEpisodePermissions(pristineModel);

    const search = useSearchParams();
    const history = useHistory();

    const [isAddClipOpen, setIsAddClipOpen] = React.useState<boolean>(search.get('addClip') ? true : false);
    const [clipView, setClipView] = React.useState('table');

    const dirtySourcesRef = React.useRef<Set<string>>(new Set());

    React.useEffect(() => {
      if (search.get('addClip')) {
        setIsAddClipOpen(true);
        history.replace({});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    React.useImperativeHandle(ref, () => ({
      getDirtySources: () => dirtySourcesRef.current,
      isSourceDirty: (id: string) => dirtySourcesRef.current.has(id),
      resetDirtySources: () => dirtySourcesRef.current.clear(),
    }));

    const [isRemoveAllOpen, setIsRemoveAllOpen] = React.useState(false);
    const [isRemoveClipOpen, setIsRemoveClipOpen] = React.useState(false);
    const [isEditDurationOpen, setIsEditDurationOpen] = React.useState(false);

    const [adPodsPopoverOpen, setAdPodsPopoverOpen] = React.useState<IPopover>({});

    const [zoomLevel, setZoomLevel] = React.useState<TZoomLevelsTimeline>(5);

    const [workingClips, setWorkingClips] = React.useState<ISimpleEntry[]>([]);
    const [activeClip, setActiveClip] = React.useState<ISimpleEntry>();
    const [activeClipIndex, setActiveClipIndex] = React.useState<number>();

    const [editAllotment, setEditAllotment] = React.useState<string>();

    const [inPoint, setInPoint] = React.useState<string>();
    const [inPointError, setInPointError] = React.useState<string>();
    const [outPoint, setOutPoint] = React.useState<string>();
    const [outPointError, setOutPointError] = React.useState<string>();

    const [markerOffset, setMarkerOffset] = React.useState<TSize>();

    const [selectedAddClip, setSelectedAddClip] = React.useState<IClip[]>();

    const [magicWandLoading, setMagicWandLoading] = React.useState<boolean>(false);
    const [autoResizeLoading, setAutoResizeLoading] = React.useState<boolean>(false);

    const playerRef = React.useRef<IPlaylistPlayerRef>(null);

    const {set: setUndo, undo, redo, canUndo, canRedo} = useUndo<ISimpleEntry[]>();

    const handleUndo = () => {
      if (!CAN_EDIT || !canUndo()) return;

      const previousClips = undo();

      if (previousClips) {
        setWorkingClips(previousClips);
      }
    };

    const handleRedo = () => {
      if (!CAN_EDIT || !canRedo()) return;

      const clips = redo();

      if (clips) {
        setWorkingClips(clips);
      }
    };

    // Create new ad pod
    useKeyboardShortcut('ctrl + e, command + e', event => {
      event.preventDefault();

      if (!CAN_EDIT || !Number.isFinite(activeClipIndex)) {
        return;
      }

      const cloned = cloneDeep(activeClip?.adPods || []);

      cloned.push({
        duration: 120,
        startAt: playerRef.current?.getCurrentTime()[0] || 0,
      });

      const uniqPods = uniqBy(cloned, (pod: IAdPodValue) => pod.startAt);

      // There is a duplicate of startAt somewhere
      if (uniqPods.length !== cloned.length) {
        Toast.error('Start times need to be unique. Please try again');
        return;
      }

      dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);

      setUndo(workingClips);
      setWorkingClips(
        workingClips.map((source, i) => ({
          ...source,
          ...(i === activeClipIndex && {
            adPods: cloned.sort((a, b) => a.startAt - b.startAt),
          }),
        })),
      );
    });

    // Delete clip
    useKeyboardShortcut('delete', event => {
      event.preventDefault();

      if (!CAN_EDIT || !Number.isFinite(activeClipIndex)) {
        return;
      }

      removeClip();
    });

    // Undo / Redo
    useKeyboardShortcut('ctrl+z, command+z', handleUndo);
    useKeyboardShortcut('shift+ctrl+z, shift+command+z', handleRedo);

    React.useEffect(() => {
      if (!pristineModel) {
        return;
      }

      (async () => {
        try {
          const transformedClips = await transformClips(pristineModel?.sources);

          if (transformedClips.length > 0) {
            const activeId = activeClipIndex ? activeClipIndex : 0;
            const updatedClips = await setActive(transformedClips, activeId);

            setUndo(updatedClips, {locked: true});
            setWorkingClips(updatedClips);
            setTimeout(() => setActiveClipIndex(activeId));
          } else {
            setUndo([], {locked: true});
            setWorkingClips([]);
          }
        } catch (e) {
          setUndo([], {locked: true});
          setWorkingClips([]);
        }
      })();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pristineModel]);

    React.useEffect(() => {
      if (Number.isFinite(activeClipIndex) && workingClips[activeClipIndex!]) {
        setActiveClip(workingClips[activeClipIndex!]);
      } else {
        setActiveClip(undefined);
      }
    }, [workingClips, activeClipIndex]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => workingClipsChanged(), [workingClips]);

    React.useEffect(() => {
      if (!activeClip) {
        return;
      }

      setInPoint(msToTimecode((activeClip.inPoint || 0) * 1000, activeClip.meta.clip.framerate, false));
      setOutPoint(
        msToTimecode(
          (activeClip.outPoint || activeClip.meta.clip.duration) * 1000,
          activeClip.meta.clip.framerate,
          false,
        ),
      );
    }, [activeClip]);

    const debouncedLevel = React.useMemo(() => debounce(val => setZoomLevel(val), 500), []);

    const allowToAddClip = React.useMemo((): boolean => {
      if (workingClips.length === 1 && workingClips[0].meta.clip.liveBroadcast) {
        return false;
      }

      return true;
    }, [workingClips]);

    const updatePlayerProgress = React.useCallback(async () => {
      if (playerRef.current) {
        const [timestamp, activeId] = await playerRef.current.getCurrentTimeline();
        setMarkerOffset(await calcMarkerOffset(workingClips, activeId, timestamp, ZOOM_LEVELS[zoomLevel].remPerMinute));
      } else {
        setMarkerOffset('0rem');
      }
    }, [workingClips, zoomLevel]);

    React.useEffect(() => {
      const timelineInterval = setInterval(() => updatePlayerProgress(), 2500);

      updatePlayerProgress();

      return () => {
        clearInterval(timelineInterval);
      };
    }, [updatePlayerProgress]);

    React.useEffect(() => {
      inOutChanged();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inPoint, outPoint]);

    const updateLiveBroadcastClip = async (sources: IEpisodeSource[]) => {
      const transformedClips = await transformClips(sources);
      const transformedClipsActive = await setActive(transformedClips, 0);

      setWorkingClips(transformedClipsActive);
      setActiveClipIndex(0);
    };

    const inOutChanged = () => {
      if (!activeClip) {
        return;
      }

      setInPointError(undefined);
      setOutPointError(undefined);

      if (!inPoint || inPoint.length < 11) {
        setInPointError('In Point is invalid');
        return;
      }

      if (!outPoint || outPoint.length < 11) {
        setOutPointError('Out Point is invalid');
        return;
      }

      const inPointTime = timecodeToMs(inPoint, activeClip.meta.clip.framerate);
      const outPointTime = timecodeToMs(outPoint, activeClip.meta.clip.framerate);

      let hasInOutError = false;

      if (inPointTime >= outPointTime) {
        setInPointError('In Point must be before Out Point');
        hasInOutError = true;
      }

      const realDuration = activeClip.meta.clip.duration || 0;

      if (inPointTime > realDuration * 1000) {
        const inPointCalculated = msToTimecode(inPointTime, activeClip.meta.clip.framerate);
        const exceededTime = msToTimecode(inPointTime - realDuration * 1000, activeClip.meta.clip.framerate);

        if (exceededTime !== '00:00:00:00') {
          setInPointError(
            `The calculated inPoint value of ${inPointCalculated} is greater than the total clip duration by ${exceededTime}. InPoint must be less than the total clip duration.`,
          );
          hasInOutError = true;
        }
      }

      if (outPointTime > realDuration * 1000) {
        const outPointCalculated = msToTimecode(outPointTime, activeClip.meta.clip.framerate);
        const exceededTime = msToTimecode(outPointTime - realDuration * 1000, activeClip.meta.clip.framerate);

        if (exceededTime !== '00:00:00:00') {
          setOutPointError(
            `The calculated outPoint value of ${outPointCalculated} is greater than the total clip duration by ${exceededTime}. OutPoint must be equal or less than the total clip duration.`,
          );
          hasInOutError = true;
        }
      }

      // Check to see if the timecode match even if the time doesn't
      if (
        msToTimecode(inPointTime, activeClip.meta.clip.framerate, false) ===
          msToTimecode((activeClip.inPoint || 0) * 1000, activeClip.meta.clip.framerate, false) &&
        msToTimecode(outPointTime, activeClip.meta.clip.framerate, false) ===
          msToTimecode((activeClip.outPoint || 0) * 1000, activeClip.meta.clip.framerate, false)
      ) {
        return;
      }

      if (setHasInOutPointWarning) {
        setHasInOutPointWarning(hasInOutError);
      }

      if (!hasInOutError) {
        setUndo(workingClips);
        setWorkingClips(
          workingClips.map((source, index) => ({
            ...source,
            ...(index === activeClipIndex && {
              inPoint: inPointTime / 1000,
              outPoint: outPointTime / 1000,
              ...(!hasInOutError && {
                adPods: [],
              }),
            }),
          })),
        );
      }
    };

    const removeClip = async () => {
      let filteredClips = cloneDeep(workingClips).filter((source, index) => index !== activeClipIndex);
      let newIndex: number | undefined = undefined;

      if (Number.isFinite(activeClipIndex)) {
        const updatedIndex = activeClipIndex! - 1 > -1 ? activeClipIndex! - 1 : 0;

        if (filteredClips[updatedIndex]) {
          filteredClips = await setActive(filteredClips, updatedIndex);
          newIndex = updatedIndex;
        }
      }

      // eslint-disable-next-line no-console
      console.log('Setting undo: ', workingClips);

      setUndo(workingClips);
      setWorkingClips(filteredClips);
      setActiveClipIndex(newIndex);
    };

    const workingClipsChanged = () => {
      if (!isValEmpty(model) && !isValEmpty(pristineModel)) {
        let episodeDuration = 0;

        const updatedSources = workingClips.map(source => {
          episodeDuration += Number.isFinite(source.outPoint)
            ? source.outPoint! - (source.inPoint || 0)
            : source.meta.duration;

          return {
            ...source.meta,
            inPoint: source.inPoint,
            outPoint: source.outPoint,
            adPods:
              (source.adPods || []).length > 0
                ? (source.adPods || []).map(pod => {
                    episodeDuration += pod.duration;

                    return {
                      ...pod,
                      startAt: Math.ceil(pod.startAt * 1000),
                      duration: pod.duration * 1000,
                      podType: 'ad',
                    };
                  })
                : null,
          };
        });

        setFields({
          duration: workingClips.length ? Math.ceil(episodeDuration) : model.duration,
          sources: updatedSources.length ? updatedSources : undefined,
          content: updatedSources.map(source => ({
            ...source,
            adPods: null,
          })),
        });
      }
    };

    const onEntryClick = React.useCallback(
      async (index: number) => {
        try {
          const updatedClips = await setActive(workingClips, index);

          setActiveClipIndex(index);
          setWorkingClips(updatedClips);
        } catch (e) {
          setActiveClipIndex(undefined);
        }
      },
      [workingClips],
    );

    const onDrop = React.useCallback(
      async (from: string, to: string) => {
        setUndo(workingClips);
        setWorkingClips(await onDropClip(workingClips, from, to));
      },
      [workingClips, setUndo],
    );

    const totalClipAdPodDuration = React.useMemo((): string => {
      if (!activeClip) {
        return '';
      }

      return secondsToHms((activeClip.adPods || []).reduce((accumulator, pod) => pod.duration + accumulator, 0));
    }, [activeClip]);

    const clipUsesDropFrames = React.useMemo((): boolean => isDropFrame(activeClip?.meta.clip.framerate), [activeClip]);

    const totalAdPodDuration = React.useMemo((): string => {
      if (!workingClips || workingClips.length === 0) {
        return '00:00:00';
      }

      return secondsToHms(
        workingClips.reduce(
          (accumulator, source) => (source.adPods || []).reduce((acc, pod) => pod.duration + acc, 0) + accumulator,
          0,
        ),
      );
    }, [workingClips]);

    const totalDuration = React.useMemo((): string => {
      if (!workingClips || workingClips.length === 0) {
        return '00:00:00';
      }

      return secondsToHms(
        Math.ceil(
          workingClips.reduce(
            (accumulator, source) =>
              (source.adPods || []).reduce((acc, pod) => pod.duration + acc, 0) +
              accumulator +
              (source.outPoint && Number.isFinite(source.outPoint)
                ? source.outPoint - (source.inPoint || 0)
                : source.meta.clip.duration),
            0,
          ),
        ),
      );
    }, [workingClips]);

    const totalEpisodeAllotment = React.useMemo((): string => {
      if (!model) {
        return '';
      }

      return secondsToHms(model.allotment || 0);
    }, [model]);

    const entryTemplate = React.useCallback(
      (entry: ISimpleEntry) => (
        <Stack space='small'>
          <Heading level='h6' color='secondary'>
            {entry.author}
          </Heading>
          <Cluster align='baseline' space='xxsmall' wrap={false}>
            <Heading level='h5' color='secondary'>
              Ad Pods:
            </Heading>
            <Heading level='h6' color='secondary'>
              {entry.adPods?.length || '0'}
            </Heading>
          </Cluster>
          <Cluster align='baseline' space='xxsmall' wrap={false}>
            <Heading level='h5' color='secondary'>
              Duration:
            </Heading>
            <Heading level='h6' color='secondary'>
              {msToTimecode(entry.duration * 1000, entry.meta.clip.framerate, false)}
            </Heading>
          </Cluster>
          {(Number.isFinite(entry.inPoint) || Number.isFinite(entry.outPoint)) && (
            <Cluster space='xxlarge'>
              {entry.inPoint?.toString().length || 0 > 0 ? (
                <Cluster align='baseline' space='xxsmall' wrap={false}>
                  <Heading level='h5' color='secondary'>
                    In:
                  </Heading>
                  <Heading level='h6' color='secondary'>
                    {msToTimecode((entry.inPoint || 0) * 1000, entry.meta.clip.framerate, false)}
                  </Heading>
                </Cluster>
              ) : (
                <></>
              )}
              {Number.isFinite(entry.outPoint) ? (
                <Cluster align='baseline' space='xxsmall' wrap={false}>
                  <Heading level='h5' color='secondary'>
                    Out:
                  </Heading>
                  <Heading level='h6' color='secondary'>
                    {msToTimecode(entry.outPoint! * 1000, entry.meta.clip.framerate, false)}
                  </Heading>
                </Cluster>
              ) : (
                <></>
              )}
            </Cluster>
          )}
          <Cluster align='baseline' space='xxsmall' wrap={false}>
            <Heading level='h5' color='secondary'>
              Created:
            </Heading>
            <Heading level='h6' color='secondary'>
              {entry?.createdAt && new Date(entry.createdAt).toLocaleDateString()}
            </Heading>
          </Cluster>
        </Stack>
      ),
      [],
    );

    const isAdPodValid = (startAt: string): string | undefined => {
      if (!startAt || startAt.length < 11) {
        return undefined;
      }

      if (inPoint) {
        if (
          timecodeToMs(inPoint, activeClip?.meta.clip.framerate) >
          timecodeToMs(startAt, activeClip?.meta.clip.framerate)
        ) {
          return 'Ad Pod must start after In Point';
        }
      }

      if (outPoint) {
        if (
          timecodeToMs(outPoint, activeClip?.meta.clip.framerate) <
          timecodeToMs(startAt, activeClip?.meta.clip.framerate)
        ) {
          return 'Ad Pod must start before Out Point';
        }
      }
    };

    const autoResizeAdPods = async () => {
      if (autoResizeLoading || magicWandLoading) {
        return;
      }

      setAutoResizeLoading(true);

      try {
        const data = await summon.put<any, IEpisode>(`episodes/${pristineModel.id}/setAdPodDurations`, {
          episode: mapPopulatedEpisodeToEpisode(model),
        });

        const clonedSources = cloneDeep(workingClips);
        const resSources = data.sources || [];

        if (clonedSources.length !== resSources.length) {
          throw new Error('Data came back differently');
        }

        for (let a = 0; a < resSources.length; a++) {
          clonedSources[a].adPods = (clonedSources[a].adPods || []).map(pod => {
            const resPod = (resSources[a].adPods || []).find(resPod => resPod.startAt / 1000 === pod.startAt);

            return {
              ...pod,
              ...(resPod && {
                duration: resPod.duration / 1000,
              }),
            };
          });
        }

        setUndo(workingClips);
        setWorkingClips(clonedSources);
      } catch (e) {
        Toast.error('Unable to auto resize ad pods. Please try again');
      }

      setAutoResizeLoading(false);
    };

    const autoFill = async () => {
      if (autoResizeLoading || magicWandLoading) {
        return;
      }

      setMagicWandLoading(true);

      try {
        const episodeId = pristineModel.id || pristineModel._id;

        const sources = (model.sources || []).map(source => ({
          ...source,
          inPoint: Math.ceil(source.inPoint),
          outPoint: Math.ceil(source.outPoint),
          clip: {
            ...source.clip,
            id: source.clip._id || source.clip.id || '',
            inPoint: source.inPoint,
            outPoint: source.outPoint,
          },
        }));

        const data = await summon.post<{episode: IMagicWand}, IClip[]>(`episodes/${episodeId}/autofill`, {
          episode: {
            ...model,
            content: sources,
            sources,
            id: episodeId,
            _id: episodeId,
            adPodsDuration: workingClips.reduce(
              (accumulator, source) => (source.adPods || []).reduce((acc, pod) => pod.duration + acc, 0) + accumulator,
              0,
            ),
          },
        });

        let newWorkingClips: ISimpleEntry[] = [];

        for (const source of data || []) {
          const foundClip =
            workingClips.find(a => a.id === source._id) || newWorkingClips.find(a => a.id === source._id);

          if (foundClip) {
            const updatedClip = cloneDeep(foundClip);

            updatedClip.inPoint = Math.ceil(source.inPoint);
            updatedClip.outPoint = !source.liveBroadcast ? Math.ceil(source.outPoint) : source.duration;
            updatedClip.duration = !source.liveBroadcast
              ? Math.ceil(
                  Number.isFinite(source.inPoint) && Number.isFinite(source.outPoint)
                    ? source.outPoint - source.inPoint
                    : source.duration,
                )
              : source.duration;

            newWorkingClips.push(updatedClip);
          } else {
            const transformed = await transformClipToEntry(source);
            newWorkingClips.push(transformed);
          }
        }

        const findActiveIndex = Math.max(
          newWorkingClips.findIndex(a => a.active),
          0,
        );
        newWorkingClips = await setActive(newWorkingClips, findActiveIndex);

        setUndo(workingClips);
        setWorkingClips(newWorkingClips);
        setActiveClipIndex(findActiveIndex);
      } catch (e) {
        Toast.error('Unable to auto fill timeline. Please try again');
      }

      setMagicWandLoading(false);
    };

    const getPinnedTimestamp = () => {
      const timestamp = playerRef.current?.getCurrentTime()[0] || 0;
      return msToTimecode(timestamp * 1000, activeClip?.meta.clip.framerate, false);
    };

    const handleAdPodClick = (icon: TIcons, index: number) => {
      if (icon === 'delete') {
        const cloned = cloneDeep(activeClip?.adPods);

        if (!cloned) {
          return;
        }

        if (Number.isFinite(activeClipIndex)) {
          dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);
        }

        cloned.splice(index, 1);

        setUndo(workingClips);
        setWorkingClips(
          workingClips.map((source, i) => ({
            ...source,
            ...(i === activeClipIndex && {
              adPods: cloned,
            }),
          })),
        );
      } else if (icon === 'edit') {
        setAdPodsPopoverOpen({[index]: true});
      }
    };

    const handleAdPodUpdate = (adPod: IAdPodValue, index = -1) => {
      const cloned = cloneDeep(activeClip?.adPods || []);

      if (index >= 0) {
        if (!cloned[index]) {
          return;
        }

        cloned[index].duration = adPod.duration;

        if (
          msToTimecode(cloned[index].startAt * 1000, activeClip?.meta.clip.framerate, false) !==
          msToTimecode(adPod.startAt * 1000, activeClip?.meta.clip.framerate, false)
        ) {
          cloned[index].startAt = adPod.startAt;
        }
      } else {
        cloned.push({
          duration: adPod.duration,
          startAt: adPod.startAt,
        });
      }

      const uniqPods = uniqBy(cloned, (pod: IAdPodValue) => pod.startAt);

      // There is a duplicate of startAt somewhere
      if (uniqPods.length !== cloned.length) {
        Toast.error('Start times need to be unique. Please try again');
        return;
      }

      if (Number.isFinite(activeClipIndex)) {
        dirtySourcesRef.current.add(workingClips[activeClipIndex!].id);
      }

      setUndo(workingClips);
      setWorkingClips(
        workingClips.map((source, i) => ({
          ...source,
          ...(i === activeClipIndex && {
            adPods: cloned.sort((a, b) => a.startAt - b.startAt),
          }),
        })),
      );

      setAdPodsPopoverOpen({});
    };

    const rowStatus = React.useCallback((row: any) => row.state, []);

    return (
      <Box background='pewter' borderTop={true} borderSize='0.125rem' borderColor='cavern' fullHeight={true}>
        <style>{css}</style>
        <Cluster fullHeight={true}>
          <Box
            paddingTop={{mobile: 'xsmall', wide: 'small'}}
            borderRight={true}
            borderSize='0.25rem'
            borderColor='shadow'
            fullHeight={true}
            overflow='auto'
            width='60%'
            widthAtTabletAndDown='50%'
            widthAtMobile='100%'
          >
            <Cover gutterTop='medium' scrolling={true} coverId='timelineContainer'>
              <Template label='header'>
                <Box paddingX={{mobile: 'medium', wide: 'xlarge'}}>
                  <Cluster justify='space-between' space='medium' align='center'>
                    <Cluster align='center' space='medium'>
                      <Heading level='h3' color='secondary'>
                        Clips
                      </Heading>
                      <Cluster space='small'>
                        <Icon
                          icon='table'
                          size='large'
                          color={clipView === 'table' ? 'primary' : 'dust'}
                          onClick={() => setClipView('table')}
                        />
                        <Icon
                          icon='playlist'
                          size='large'
                          color={clipView === 'timeline' ? 'primary' : 'dust'}
                          onClick={() => setClipView('timeline')}
                        />
                      </Cluster>
                    </Cluster>
                    <Cluster space='medium' align='center'>
                      {clipView === 'timeline' && (
                        <Range size='xsmall' min={0} max={6} onChange={debouncedLevel} value={zoomLevel} />
                      )}
                      {CAN_EDIT && (
                        <Popover trigger='mouseenter' appendToBody={true}>
                          <Template label='trigger'>
                            <Icon
                              icon='shuffle'
                              onClick={() => {
                                if (autoResizeLoading || magicWandLoading) {
                                  return;
                                }

                                const shuffledClips = shuffle(workingClips);
                                const foundActive = Math.max(
                                  0,
                                  shuffledClips.findIndex(c => c.active),
                                );

                                setUndo(workingClips);
                                setWorkingClips(shuffledClips);
                                setActiveClipIndex(foundActive);
                              }}
                            />
                          </Template>
                          <Template label='popover'>Shuffle</Template>
                        </Popover>
                      )}
                      {CAN_EDIT && (
                        <Popover trigger='mouseenter' appendToBody={true}>
                          <Template label='trigger'>
                            {magicWandLoading ? (
                              <Spinner size='small' />
                            ) : (
                              <Icon icon='autofix' size='large' onClick={() => autoFill()} />
                            )}
                          </Template>
                          <Template label='popover'>Auto Fill</Template>
                        </Popover>
                      )}
                      {CAN_EDIT && (
                        <Popover trigger='mouseenter' appendToBody={true}>
                          <Template label='trigger'>
                            {autoResizeLoading ? (
                              <Spinner size='small' />
                            ) : (
                              <Icon icon='arrowleftright' size='large' onClick={() => autoResizeAdPods()} />
                            )}
                          </Template>
                          <Template label='popover'>Auto Resize Ad Pods</Template>
                        </Popover>
                      )}
                      {CAN_EDIT && (
                        <Popover
                          manualTrigger={true}
                          visible={isRemoveAllOpen}
                          onClickOutside={() => setIsRemoveAllOpen(false)}
                        >
                          <Template label='trigger'>
                            <Icon
                              icon='deleteall'
                              hoverColor='delete'
                              onClick={() => setIsRemoveAllOpen(isRemoveAllOpen => !isRemoveAllOpen)}
                            />
                          </Template>
                          <Template label='popover'>
                            <Box padding={popoverActionsProps.padding} background={popoverActionsProps.background}>
                              <Stack space='small'>
                                <Icon
                                  icon='delete'
                                  textColor='delete'
                                  color='delete'
                                  hoverColor='deleteLight'
                                  space='xxsmall'
                                  onClick={() => {
                                    if (autoResizeLoading || magicWandLoading) {
                                      return;
                                    }

                                    setUndo(workingClips);
                                    setWorkingClips([]);
                                    setIsRemoveAllOpen(false);
                                  }}
                                >
                                  Remove Selected Clips
                                </Icon>
                                <Icon
                                  icon='delete'
                                  textColor='delete'
                                  color='delete'
                                  hoverColor='deleteLight'
                                  space='xxsmall'
                                  onClick={() => {
                                    if (autoResizeLoading || magicWandLoading) {
                                      return;
                                    }

                                    setUndo(workingClips);
                                    setWorkingClips(
                                      workingClips.map(source => ({
                                        ...source,
                                        adPods: [],
                                      })),
                                    );
                                    setIsRemoveAllOpen(false);
                                  }}
                                >
                                  Remove Ad Pods From Selected Clips
                                </Icon>
                                <Cluster justify='space-between'>
                                  <div></div>
                                  <Cluster>
                                    <Button ghost={true} onClick={() => setIsRemoveAllOpen(false)}>
                                      Cancel
                                    </Button>
                                  </Cluster>
                                </Cluster>
                              </Stack>
                            </Box>
                          </Template>
                        </Popover>
                      )}
                      <Button
                        type='primary'
                        permission={editPermission}
                        onClick={() => setIsAddClipOpen(true)}
                        state={allowToAddClip ? '' : 'disabled'}
                      >
                        + Add Clip
                      </Button>
                      <Dialog isOpen={isAddClipOpen} onClose={() => setIsAddClipOpen(false)} width='100%' height='100%'>
                        <Template label='header'>
                          <Heading level='h2'>Add Clip(s)</Heading>
                        </Template>
                        <Template label='body'>
                          <ClipList
                            actionsCol={false}
                            addNewClips={false}
                            checkboxCol='multiple'
                            presetSearch={{
                              activeRegion: model.activeRegion ? [model.activeRegion] : undefined,
                              published: true,
                            }}
                            inModal
                            isSearchExpanded
                            onSelect={val => setSelectedAddClip(val as IClip[])}
                            existingClipsId={workingClips?.map(clip => clip.id) || []}
                            trackClipsSortList={true}
                            totalDuration={totalDuration}
                            totalAllotment={totalEpisodeAllotment}
                          />
                        </Template>
                        <Template label='footer'>
                          <Cluster justify='space-between'>
                            <div></div>
                            <Cluster space='small'>
                              <Button
                                ghost={true}
                                size='small'
                                onClick={() => {
                                  setIsAddClipOpen(false);
                                  setSelectedAddClip(undefined);
                                }}
                              >
                                Cancel
                              </Button>
                              <Button
                                type='primary'
                                size='small'
                                state={!selectedAddClip ? 'disabled' : ''}
                                onClick={async () => {
                                  if (!selectedAddClip || selectedAddClip.length === 0) {
                                    return;
                                  }

                                  const newClips: ISimpleEntry[] = [];
                                  let newClipError: IClip | undefined;
                                  let clipError =
                                    'is not ready to be added to episode. Please select a different clip.';

                                  const liveBroadcastClips = selectedAddClip.filter(clip => clip.liveBroadcast);

                                  if (liveBroadcastClips.length > 1) {
                                    Toast.error('You cannot add more than 1 live broadcast clip to an episode.');
                                    return;
                                  }

                                  if (liveBroadcastClips.length > 0 && selectedAddClip.length >= 2) {
                                    Toast.error(
                                      'You cannot add a live broadcast clip and a VOD clip to the same episode.',
                                    );
                                    return;
                                  }

                                  for (const clip of selectedAddClip) {
                                    if (!Number.isFinite(clip.duration) || clip.duration === 0) {
                                      newClipError = clip;
                                      break;
                                    }

                                    if (clip.outPoint === 0 && !clip.liveBroadcast) {
                                      newClipError = clip;
                                      break;
                                    }

                                    if (!clip.published) {
                                      newClipError = clip;
                                      clipError =
                                        'cannot be added to the episode because the clip is not published. Please update, or select a different clip.';
                                      break;
                                    }

                                    if (clip.liveBroadcast && workingClips.length > 0) {
                                      newClipError = clip;
                                      clipError =
                                        'please remove existing clips in order to add a liveBroadcast clip to this episode.';
                                      break;
                                    }

                                    newClips.push(
                                      await transformClipToEntry(
                                        {
                                          ...clip,
                                          ...(clip.liveBroadcast && {
                                            outPoint: (clip.inPoint || 0) + model.allotment!,
                                          }),
                                        },
                                        true,
                                      ),
                                    );
                                  }

                                  if (newClipError) {
                                    Toast.error(`${newClipError.name} ${clipError}`);

                                    return;
                                  }

                                  const newActiveIndex = workingClips.length;
                                  const updatedClips = await setActive([...workingClips, ...newClips], newActiveIndex);

                                  setUndo(workingClips);
                                  setWorkingClips(updatedClips);
                                  setActiveClipIndex(newActiveIndex);
                                  setIsAddClipOpen(false);
                                  setSelectedAddClip(undefined);
                                }}
                              >
                                Add Clip
                              </Button>
                            </Cluster>
                          </Cluster>
                        </Template>
                      </Dialog>
                    </Cluster>
                  </Cluster>
                </Box>
              </Template>
              <Template label='cover'>
                <Box
                  fullHeight={true}
                  overflow='auto'
                  paddingLeft={{mobile: 'medium', wide: 'xlarge'}}
                  paddingRight={{mobile: 'medium', wide: 'none'}}
                >
                  {clipView === 'timeline' && (
                    <Timeline
                      type='timeline'
                      zoomLevel={zoomLevel}
                      entries={workingClips}
                      onEntryClick={onEntryClick}
                      onDrop={onDrop}
                      transformEntry={entryTemplate}
                      markerOffset={markerOffset}
                    />
                  )}
                  {clipView === 'table' && (
                    <Box overflow='auto' fullHeight={true} borderTop={true} borderColor='mist' borderSize='0.0625rem'>
                      <Cluster growNthChild={2} space='medium' wrap={false}>
                        <Box background='graphite'>
                          <Table
                            size='small'
                            stickyHeader={true}
                            headerBackgroundColor='graphite'
                            lightDividers={true}
                            cols={[
                              {
                                label: 'Start Time',
                                field: 'startTime',
                              },
                            ]}
                            rows={[
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                              {
                                startTime: '00:00:00',
                              },
                            ]}
                          ></Table>
                        </Box>
                        <Table
                          size='small'
                          wrapContent={true}
                          stickyHeader={true}
                          draggable={true}
                          //onSelect={() => alert('Select Changed')}
                          selectable='multiple'
                          rowStatus={rowStatus}
                          cols={[
                            {
                              label: '',
                              zeroRightPadding: true,
                              colWidth: '1.25rem',
                              transform: row => {
                                if (!row.errorMessagesWithState?.length) {
                                  return null;
                                }
                                let state = 'warning';
                                row.errorMessagesWithState.forEach(a => {
                                  if (a.state === 'error') {
                                    state = 'error';
                                  }
                                });
                                return (
                                  <Popover trigger='mouseenter'>
                                    <Template label='trigger'>
                                      <Icon icon='warning' verticalAlign='text-bottom' color={state as any} />
                                    </Template>
                                    <Template label='popover'>
                                      <Box
                                        background={popoverActionsProps.background}
                                        width={popoverActionsProps.widthWide}
                                        paddingX='small'
                                        paddingY='xxsmall'
                                      >
                                        <Stack space='xxxsmall'>
                                          {row.errorMessagesWithState.map((validatorMessage, index) => (
                                            <div key={index}>
                                              <Icon
                                                icon='warning'
                                                iconAlign='baseline'
                                                space='small'
                                                color={validatorMessage.state as any}
                                              >
                                                {validatorMessage.message}
                                              </Icon>
                                            </div>
                                          ))}
                                        </Stack>
                                      </Box>
                                    </Template>
                                  </Popover>
                                );
                              },
                            },
                            {
                              label: 'Name',
                              transform: row => (
                                <Stack space='xxxsmall'>
                                  <TdMultilineTruncate
                                    text={row.title}
                                    title={row.title}
                                    row={row}
                                    onClick={() => alert('Clicked')}
                                  />
                                  <Cluster space='xxsmall'>
                                    <Paragraph size='xsmall' color='secondary'>
                                      In Point:{' '}
                                      {msToTimecode((row.inPoint || 0) * 1000, row.meta.clip.framerate, false)}{' '}
                                    </Paragraph>
                                    <Divider direction='vertical' flexChild={true} color='dust' />
                                    <Paragraph size='xsmall' color='secondary'>
                                      Out Point: {msToTimecode(row.outPoint! * 1000, row.meta.clip.framerate, false)}
                                    </Paragraph>
                                  </Cluster>
                                </Stack>
                              ),
                              colMinWidth: '15.625rem',
                            },
                            {
                              label: 'Author',
                              transform: row => (
                                <TdMultilineTruncate text={row.author} title={row.author} color='secondary' row={row} />
                              ),
                              colMinWidth: '10.9375rem',
                            },
                            {
                              label: 'Created At',
                              transform: row => row.createdAt && new Date(row.createdAt).toLocaleDateString(),
                              colWidth: '5.3125rem',
                              colMinWidth: '5.3125rem',
                            },
                            {
                              label: 'Duration',
                              transform: row => msToTimecode(row.duration * 1000, row.meta.clip.framerate, false),
                              colWidth: '5.625rem',
                            },
                            {
                              label: 'Pods',
                              transform: row => row.adPods?.length,
                              colWidth: '2.8125rem',
                            },
                          ]}
                          rows={workingClips}
                        ></Table>
                      </Cluster>
                    </Box>
                  )}
                </Box>
              </Template>
              <Template label='footer'>
                <Box
                  paddingY={{mobile: 'medium', wide: 'large'}}
                  paddingX={{mobile: 'medium', wide: 'xlarge'}}
                  borderTop={true}
                  borderSize='0.25rem'
                  borderColor='shadow'
                >
                  <Cluster justify='space-between'>
                    <div></div>
                    <Cluster space='large'>
                      <Stack space='xsmall'>
                        <Cluster space='medium' justify='space-between'>
                          <Heading level='h6' color='secondary'>
                            Total AdPod Duration
                          </Heading>
                          <Heading level='h6' monospace={true} monospaceWeight='normal' color='secondary'>
                            {totalAdPodDuration}
                          </Heading>
                        </Cluster>
                        <Cluster space='medium' justify='space-between'>
                          <Heading level='h5'>
                            Total
                            Duration&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </Heading>
                          <Cluster space='xxxxsmall' align='center'>
                            <Heading level='h5' monospace={true} monospaceWeight='bold'>
                              {totalDuration}
                            </Heading>
                            <Heading level='h6' monospace={true} monospaceWeight='normal' color='secondary'>
                              /
                            </Heading>
                            {CAN_EDIT ? (
                              <Popover
                                appendToBody={true}
                                manualTrigger={true}
                                visible={isEditDurationOpen}
                                onClickOutside={() => setIsEditDurationOpen(false)}
                              >
                                <Template label='trigger'>
                                  <Click
                                    onClick={() => setIsEditDurationOpen(isEditDurationOpen => !isEditDurationOpen)}
                                  >
                                    <Heading level='h6' monospace={true} monospaceWeight='normal' color='highlight'>
                                      {totalEpisodeAllotment}
                                    </Heading>
                                  </Click>
                                </Template>
                                <Template label='popover'>
                                  <Box padding='small' background='charcoal'>
                                    <Stack space='small'>
                                      <FormItem label='Episode Allotment'>
                                        <TextInput
                                          value={totalEpisodeAllotment}
                                          fixedPlaceholder='HH:MM:SS'
                                          mask='NN:[0-5]N:[0-5]N'
                                          onChange={val => setEditAllotment(val)}
                                        />
                                      </FormItem>
                                      <Cluster justify='space-between'>
                                        <div></div>
                                        <Cluster space='small'>
                                          <Button ghost={true} onClick={() => setIsEditDurationOpen(false)}>
                                            Cancel
                                          </Button>
                                          <Button
                                            type='primary'
                                            state={!editAllotment || editAllotment.length < 8 ? 'disabled' : ''}
                                            onClick={() => {
                                              if (!editAllotment || editAllotment.length < 8) {
                                                return;
                                              }

                                              const newAllotment = hmsToSeconds(editAllotment);
                                              const sourcesUpdated = cloneDeep(model.sources) || [];

                                              if (sourcesUpdated.length === 1 && sourcesUpdated[0].clip.liveBroadcast) {
                                                sourcesUpdated[0] = {
                                                  ...sourcesUpdated[0],
                                                  outPoint: (sourcesUpdated[0].inPoint || 0) + newAllotment,
                                                  clip: {
                                                    ...sourcesUpdated[0].clip,
                                                    outPoint: (sourcesUpdated[0].clip.inPoint || 0) + newAllotment,
                                                  },
                                                };
                                              }

                                              setFields({
                                                sources: sourcesUpdated,
                                                allotment: newAllotment,
                                              });

                                              setIsEditDurationOpen(false);
                                              updateLiveBroadcastClip(sourcesUpdated);
                                            }}
                                          >
                                            Edit Duration
                                          </Button>
                                        </Cluster>
                                      </Cluster>
                                    </Stack>
                                  </Box>
                                </Template>
                              </Popover>
                            ) : (
                              <Heading level='h6' monospace={true} monospaceWeight='normal' color='highlight'>
                                {totalEpisodeAllotment}
                              </Heading>
                            )}
                          </Cluster>
                        </Cluster>
                      </Stack>
                    </Cluster>
                  </Cluster>
                </Box>
              </Template>
            </Cover>
          </Box>
          {activeClip ? (
            <Box width='40%' widthAtTabletAndDown='50%' widthAtMobile='100%' overflow='auto' fullHeight={true}>
              <Cover gutterTop='medium'>
                <Template label='header'>
                  <Box
                    borderBottom={true}
                    borderSize='0.25rem'
                    borderColor='shadow'
                    paddingTop={{mobile: 'medium', wide: 'large'}}
                    paddingBottom={{mobile: 'medium', wide: 'xlarge'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    fullHeight={true}
                  >
                    <Stack space='large'>
                      <Heading level='h3' color='secondary'>
                        Clip Preview
                      </Heading>
                      <Stack space='xlarge'>
                        <PlaylistPlayer
                          ref={playerRef}
                          from='meta'
                          height='20.625rem'
                          clips={workingClips}
                          id='preview'
                          activeIndex={activeClipIndex}
                          onActiveChanged={onEntryClick}
                          showControls={false}
                        />
                        <Cluster
                          align='center'
                          justify='center'
                          space={{mobile: 'xxlarge', tablet: 'xxlarge', desktop: 'xxlarge', wide: 'xxxxxxlarge'}}
                        >
                          <Stack space='xsmall'>
                            <Label>Computed Clip Duration</Label>
                            <Heading level='h4'>
                              {msToTimecode(
                                ((isNumber(activeClip.outPoint)
                                  ? activeClip.outPoint
                                  : isNumber(activeClip.meta.outPoint)
                                  ? activeClip.meta.outPoint
                                  : 0) -
                                  (isNumber(activeClip.inPoint)
                                    ? activeClip.inPoint
                                    : isNumber(activeClip.meta.inPoint)
                                    ? activeClip.meta.inPoint
                                    : 0)) *
                                  1000,
                                activeClip.meta.clip.framerate,
                              )}
                            </Heading>
                          </Stack>
                          <Heading level='h4'>
                            <Icon
                              linkTarget='_blank'
                              href={'/clips/' + activeClip.id}
                              icon='open'
                              color='primary'
                              hoverColor='primaryLight'
                              textColor='primary'
                              space='xxsmall'
                              textDecoration={false}
                            >
                              View Clip
                            </Icon>
                          </Heading>
                          {CAN_EDIT && (
                            <Heading level='h4'>
                              <Popover
                                manualTrigger={true}
                                visible={isRemoveClipOpen}
                                onClickOutside={() => setIsRemoveClipOpen(false)}
                              >
                                <Template label='trigger'>
                                  <Icon
                                    icon='delete'
                                    color='delete'
                                    textColor='delete'
                                    hoverColor='deleteLight'
                                    space='xxsmall'
                                    onClick={() => setIsRemoveClipOpen(isRemoveClipOpen => !isRemoveClipOpen)}
                                  >
                                    Remove Clip
                                  </Icon>
                                </Template>
                                <Template label='popover'>
                                  <DeleteConfirmation
                                    message='Are you sure you want to remove this clip?'
                                    cancelButtonFunction={() => setIsRemoveClipOpen(false)}
                                    proceedButtonFunction={async () => {
                                      setIsRemoveClipOpen(false);
                                      removeClip();
                                    }}
                                  />
                                </Template>
                              </Popover>
                            </Heading>
                          )}
                        </Cluster>
                      </Stack>
                    </Stack>
                  </Box>
                </Template>
                <Template label='cover'>
                  <Box
                    paddingY={{mobile: 'xxxxxxsmall', wide: 'xxsmall'}}
                    paddingX={{mobile: 'medium', wide: 'xlarge'}}
                    fullHeight={true}
                  >
                    <Stack space='large'>
                      <Icon icon='info' size='medium' iconAlign='center' space='xxsmall' color='info'>
                        <Help state='info'>Ad Pods will be cleared if In/Out Points are modified</Help>
                      </Icon>
                      <Cluster
                        space='xlarge'
                        wrap={false}
                        fullHeight={true}
                        fullWidth={true}
                        growNthChild={2}
                        noShrinkNthChild={1}
                      >
                        <Box width='14rem'>
                          <Stack space='xlarge'>
                            <Heading level='h3' color='secondary'>
                              In/Out Points
                            </Heading>
                            <Stack space='small'>
                              <FormItem
                                label='In Point'
                                permission={editPermission}
                                state={inPointError ? 'error' : activeClip.meta.clip.liveBroadcast ? 'disabled' : ''}
                                helpText={inPointError}
                              >
                                <TextInput
                                  value={inPoint}
                                  {...(CAN_EDIT && {iconRight: 'playhead'})}
                                  onChange={val => setInPoint(val)}
                                  onIconRightClick={() => setInPoint(getPinnedTimestamp())}
                                  fixedPlaceholder={
                                    clipUsesDropFrames
                                      ? dropFramePlaceholder.placeholder
                                      : nonDropFramePlaceholder.placeholder
                                  }
                                  mask={clipUsesDropFrames ? dropFramePlaceholder.mask : nonDropFramePlaceholder.mask}
                                />
                              </FormItem>
                              <FormItem
                                label='Out Point'
                                permission={editPermission}
                                state={outPointError ? 'error' : activeClip.meta.clip.liveBroadcast ? 'disabled' : ''}
                                helpText={outPointError}
                              >
                                <TextInput
                                  value={outPoint}
                                  {...(CAN_EDIT && {iconRight: 'playhead'})}
                                  onChange={val => setOutPoint(val)}
                                  onIconRightClick={() => setOutPoint(getPinnedTimestamp())}
                                  fixedPlaceholder={
                                    clipUsesDropFrames
                                      ? dropFramePlaceholder.placeholder
                                      : nonDropFramePlaceholder.placeholder
                                  }
                                  mask={clipUsesDropFrames ? dropFramePlaceholder.mask : nonDropFramePlaceholder.mask}
                                />
                              </FormItem>
                            </Stack>
                          </Stack>
                        </Box>
                        <Box fullHeight={true} overflow='auto'>
                          <Cover scrolling={true} minHeight='auto' stickyFooter={false} gutter='xsmall'>
                            <Template label='header'>
                              <Heading level='h3' color='secondary'>
                                AD Pods
                              </Heading>
                            </Template>
                            <Template label='cover'>
                              <Table
                                fixedHeader={true}
                                fixedFirstCol={true}
                                cols={[
                                  {
                                    label: 'Time Stamp',
                                    transform: row => (
                                      <Click
                                        color='primary'
                                        hoverColor='primaryLight'
                                        onClick={() => playerRef.current?.seekTo(row.startAt)}
                                      >
                                        {msToTimecode(row.startAt * 1000, activeClip.meta.clip.framerate, false)}
                                      </Click>
                                    ),
                                    fixed: true,
                                  },
                                  {
                                    label: 'Duration',
                                    transform: row => `${row.duration}s`,
                                  },
                                  ...(CAN_EDIT && !activeClip.meta.clip.liveBroadcast
                                    ? [
                                        {
                                          label: 'Actions',
                                          colWidth: '6.25rem',
                                          transform: (row, _col, index) => (
                                            <TableActions
                                              row={row}
                                              icons={[]}
                                              deleteOption={true}
                                              altTitle='Ad Pod'
                                              onClick={(_row, icon) => handleAdPodClick(icon, index)}
                                            >
                                              <Popover
                                                appendToBody={true}
                                                manualTrigger={true}
                                                visible={adPodsPopoverOpen[index]}
                                                onClickOutside={() => setAdPodsPopoverOpen({})}
                                              >
                                                <Template label='trigger'>
                                                  <Icon
                                                    space='small'
                                                    icon='edit'
                                                    onClick={() => handleAdPodClick('edit', index)}
                                                  />
                                                </Template>
                                                <Template label='popover'>
                                                  <AdPodForm
                                                    value={{
                                                      duration: row.duration,
                                                      startAt: row.startAt,
                                                    }}
                                                    visible={adPodsPopoverOpen[index]}
                                                    onCancel={() => setAdPodsPopoverOpen({})}
                                                    onPin={() =>
                                                      playerRef.current
                                                        ? playerRef.current.getCurrentTime()[0] * 1000
                                                        : 0
                                                    }
                                                    fixedPlaceholder={
                                                      clipUsesDropFrames
                                                        ? dropFramePlaceholder.placeholder
                                                        : nonDropFramePlaceholder.placeholder
                                                    }
                                                    mask={
                                                      clipUsesDropFrames
                                                        ? dropFramePlaceholder.mask
                                                        : nonDropFramePlaceholder.mask
                                                    }
                                                    isValid={isAdPodValid}
                                                    onSubmit={podData => handleAdPodUpdate(podData, index)}
                                                    framerate={activeClip.meta.clip.framerate}
                                                  />
                                                </Template>
                                              </Popover>
                                            </TableActions>
                                          ),
                                        } as ITableCol<ISimpleAdPod>,
                                      ]
                                    : []),
                                ]}
                                rows={activeClip.adPods || []}
                              ></Table>
                            </Template>
                            <Template label='footer'>
                              <Cluster justify='space-between'>
                                <div></div>
                                <Cluster space='medium' align='center'>
                                  <Cluster space='medium'>
                                    <Heading level='h6' color='secondary'>
                                      Total AdPod Duration:
                                    </Heading>
                                    <Heading level='h6' monospace={true} monospaceWeight='normal' color='secondary'>
                                      {totalClipAdPodDuration}
                                    </Heading>
                                  </Cluster>
                                  {CAN_EDIT && !activeClip.meta.clip.liveBroadcast && (
                                    <Popover
                                      manualTrigger={true}
                                      visible={adPodsPopoverOpen.add}
                                      onClickOutside={() => setAdPodsPopoverOpen({})}
                                      appendToBody={true}
                                    >
                                      <Template label='trigger'>
                                        <Button
                                          type='primary'
                                          permission={editPermission}
                                          onClick={() => setAdPodsPopoverOpen({add: true})}
                                        >
                                          + Add Ad Pod
                                        </Button>
                                      </Template>
                                      <Template label='popover'>
                                        <AdPodForm
                                          visible={adPodsPopoverOpen.add}
                                          onCancel={() => setAdPodsPopoverOpen({})}
                                          onPin={() =>
                                            playerRef.current ? playerRef.current.getCurrentTime()[0] * 1000 : 0
                                          }
                                          fixedPlaceholder={
                                            clipUsesDropFrames
                                              ? dropFramePlaceholder.placeholder
                                              : nonDropFramePlaceholder.placeholder
                                          }
                                          mask={
                                            clipUsesDropFrames
                                              ? dropFramePlaceholder.mask
                                              : nonDropFramePlaceholder.mask
                                          }
                                          isValid={isAdPodValid}
                                          onSubmit={handleAdPodUpdate}
                                          framerate={activeClip.meta.clip.framerate}
                                        />
                                      </Template>
                                    </Popover>
                                  )}
                                </Cluster>
                              </Cluster>
                            </Template>
                          </Cover>
                        </Box>
                      </Cluster>
                    </Stack>
                  </Box>
                </Template>
              </Cover>
            </Box>
          ) : (
            <Box width='40.9375rem' widthAtTabletAndDown='50%' widthAtMobile='100%' overflow='auto' fullHeight={true}>
              <Cover center={true}>
                <Template label='cover'>
                  <Stack space='large' alignItems='center'>
                    <Heading level='h3'>There are no clips in this episode yet.</Heading>
                    <Button type='primary' onClick={() => setIsAddClipOpen(true)} permission={editPermission}>
                      + Add Clip
                    </Button>
                  </Stack>
                </Template>
              </Cover>
            </Box>
          )}
        </Cluster>
      </Box>
    );
  },
);

export default EpisodeVideo;
