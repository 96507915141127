import * as React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {some} from 'lodash-es';
import {Accordion, Box, Click, Cover, Cluster, Expand, Icon, Popover, Stack, Template} from '@pluto-tv/assemble';

import {IRouteItem, TRouteCollection} from 'routes';

import Logo from 'components/logo';
import User from 'components/user';

interface IRoutePopover {
  [key: string]: boolean;
}

export interface IAppSidebarProps {
  getRoutes: () => TRouteCollection;
  hasRoles: boolean;
  picture?: string;
  name?: string;
  email?: string;
  isExpanded?: boolean;
}

const AppSidebar = React.memo(({getRoutes, picture, name, email, isExpanded = false, hasRoles}: IAppSidebarProps) => {
  const history = useHistory();
  const location = useLocation();
  const [popoverVisible, setPopoverVisible] = React.useState<IRoutePopover>({});

  const menuItemClickHandler = (targetPath: string) => {
    history.push(targetPath);
    setPopoverVisible({});
  };

  const [expanded, setExpanded] = React.useState<boolean>(isExpanded);
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const getNavActiveColor = (route: IRouteItem, includeChildren = false) => {
    if (
      route.path === location.pathname ||
      location.pathname.indexOf(route.path!) === 0 ||
      (includeChildren &&
        route.children &&
        some(route.children, child => child.path === location.pathname || location.pathname.indexOf(child.path!) > -1))
    ) {
      return 'primary';
    }

    return undefined;
  };

  return (
    <Cover minHeight='auto'>
      <Template label='header'>
        <Box
          paddingX='xxxsmall'
          paddingTop='none'
          paddingBottom='none'
          background='black'
          backgroundHover='black'
          onClick={() => menuItemClickHandler('/')}
        >
          <Logo expanded={expanded} />
        </Box>
      </Template>
      <Template label='cover'>
        {hasRoles ? (
          <>
            <Box padding={{mobile: 'xxsmall', wide: 'medium'}} hideAt='mobile'>
              <Cluster space='large'>
                {getRoutes()
                  .filter(r => r.showInSideBar && r.icon)
                  .map(r => (
                    <Expand isExpanded={expanded} width='12.5rem' notExpandedHeight='auto' key={r.name}>
                      <Template label='fixed'>
                        {expanded && <Icon icon={r.icon!} size='xlarge' color={getNavActiveColor(r, true)} />}
                        {!expanded && r.children && (
                          <Popover
                            manualTrigger={true}
                            visible={popoverVisible[r.name]}
                            onClickOutside={() => setPopoverVisible(prev => ({...prev, [r.name]: false}))}
                          >
                            <Template label='trigger'>
                              <Icon
                                icon={r.icon!}
                                size='xlarge'
                                color={getNavActiveColor(r, true)}
                                onClick={() => setPopoverVisible(prev => ({...prev, [r.name]: !prev[r.name]}))}
                              />
                            </Template>
                            <Template label='popover'>
                              <Box paddingX='xxsmall' paddingY='xxxxsmall'>
                                <Stack space='small'>
                                  {r.children.map(childRoute => (
                                    <Click
                                      key={childRoute.name}
                                      color={getNavActiveColor(childRoute)}
                                      onClick={() => menuItemClickHandler(childRoute.path!)}
                                    >
                                      {childRoute.name}
                                    </Click>
                                  ))}
                                </Stack>
                              </Box>
                            </Template>
                          </Popover>
                        )}
                        {!expanded && !r.children && (
                          <Icon
                            icon={r.icon!}
                            size='xlarge'
                            color={getNavActiveColor(r, true)}
                            onClick={() => menuItemClickHandler(r.path!)}
                          />
                        )}
                      </Template>
                      <Template label='expandable'>
                        <Box paddingTop='xxxxsmall'>
                          {!r.children && (
                            <Click
                              display='block'
                              truncate={true}
                              color={getNavActiveColor(r)}
                              onClick={() => menuItemClickHandler(r.path!)}
                            >
                              {r.name}
                            </Click>
                          )}
                          {r.children && (
                            <Accordion isOpen={false} arrowTriggerAlign='flex-start'>
                              <Template label='trigger'>
                                <Click display='block' truncate={true}>
                                  {r.name!}
                                </Click>
                              </Template>
                              <Template label='body'>
                                <Box paddingLeft='medium' paddingTop='xxxsmall' paddingBottom='xxxxxxsmall'>
                                  <Stack space='small'>
                                    {r.children.map(childRoute => (
                                      <Click
                                        key={childRoute.name}
                                        size='small'
                                        color={getNavActiveColor(childRoute)}
                                        onClick={() => menuItemClickHandler(childRoute.path!)}
                                      >
                                        {childRoute.name}
                                      </Click>
                                    ))}
                                  </Stack>
                                </Box>
                              </Template>
                            </Accordion>
                          )}
                        </Box>
                      </Template>
                    </Expand>
                  ))}
              </Cluster>
            </Box>
            <Box
              paddingTop='xsmall'
              paddingBottom='xxsmall'
              paddingX='small'
              position='absolute'
              background='black'
              width='calc(100% - 3.0375rem)'
              height='2.436875rem'
              top='0px'
              left='3.0375rem'
              hideAt='tabletAndUp'
            >
              <Cluster space='large'>
                {getRoutes()
                  .filter(r => r.showInSideBar && r.icon)
                  .map(r => (
                    <Popover
                      key={r.name}
                      manualTrigger={true}
                      visible={popoverVisible[r.name]}
                      onClickOutside={() => setPopoverVisible(prev => ({...prev, [r.name]: false}))}
                      allowedPlacements={['bottom-start']}
                    >
                      <Template label='trigger'>
                        <Icon
                          icon={r.icon!}
                          size='xlarge'
                          color={getNavActiveColor(r, true)}
                          onClick={() => setPopoverVisible(prev => ({...prev, [r.name]: !prev[r.name]}))}
                        />
                      </Template>
                      <Template label='popover'>
                        <Box paddingX='xxsmall' paddingY='xxxxsmall'>
                          <Stack space='small'>
                            {r.children?.map(childRoute => (
                              <Click
                                key={childRoute.name}
                                color={getNavActiveColor(childRoute)}
                                onClick={() => menuItemClickHandler(childRoute.path!)}
                              >
                                {childRoute.name}
                              </Click>
                            ))}
                          </Stack>
                        </Box>
                      </Template>
                    </Popover>
                  ))}
              </Cluster>
            </Box>
          </>
        ) : (
          <></>
        )}
      </Template>
      <Template label='footer'>
        <>
          <Box
            paddingY={{mobile: 'xxsmall', wide: 'medium'}}
            paddingX={{mobile: 'xxsmall', wide: 'xsmall'}}
            hideAt='mobile'
          >
            <Cluster space='large'>
              <User email={email!} isExpanded={expanded} name={name!} avatar={picture} />
              <Box paddingLeft='xxxxsmall'>
                <Icon
                  icon='chevronright'
                  size='xlarge'
                  rotate={expanded ? '-180deg' : '0deg'}
                  onClick={toggleExpanded}
                  id='multiButtonIcon'
                />
              </Box>
            </Cluster>
          </Box>
          <Box padding='none' position='absolute' top='0.25rem' right='0.3125rem' hideAt='tabletAndUp'>
            <User email={email!} isExpanded={expanded} name={name!} avatar={picture} />
          </Box>
        </>
      </Template>
    </Cover>
  );
});

AppSidebar.displayName = 'AppSidebar';
export default AppSidebar;
