import * as React from 'react';
import {IChannelCatalogItem} from 'models/channelCatalog';

export const useChannelCatalogQueueDuration = (
  queueDuration: number,
  movedToQueue: IChannelCatalogItem[] = [],
  movedToQueueDuringFilter: IChannelCatalogItem[] = [],
  isSearchActive: boolean,
): {duration: number} => {
  const duration = React.useMemo(() => {
    if (isSearchActive) {
      const totalMovedToQueueDuringFilterDuration = movedToQueueDuringFilter.reduce(
        (acc, item) => acc + item.duration,
        0,
      );
      return queueDuration + totalMovedToQueueDuringFilterDuration;
    }

    const totalMovedToQueueDuration = movedToQueue.reduce((acc, item) => acc + item.duration, 0);
    return queueDuration + totalMovedToQueueDuration;
  }, [isSearchActive, movedToQueue, movedToQueueDuringFilter, queueDuration]);

  return {
    duration,
  };
};
