import * as React from 'react';
import {IChannelCatalogItem, IChannelCatalogQuery} from 'models/channelCatalog';
import {filterQueue} from 'views/programming/channel/edit/catalog/utils/catalogQueueFilterHelper';
import {IChannelCatalogItemWithState} from 'views/programming/channel/contexts/memoryQueueApiProvider';

export const useTotalLibraryCount = ({
  totalLength,
  movedToQueue,
  isSearchActive,
  searchParams,
}: {
  totalLength: number;
  movedToQueue: IChannelCatalogItem[];
  isSearchActive: boolean;
  searchParams: Partial<IChannelCatalogQuery>;
}): number => {
  return React.useMemo(() => {
    if (isSearchActive) {
      const filteredQueue = filterQueue(movedToQueue as IChannelCatalogItemWithState[], searchParams);
      return totalLength - filteredQueue.length;
    }
    return totalLength - movedToQueue.length;
  }, [isSearchActive, movedToQueue, searchParams, totalLength]);
};
