import React from 'react';
import {useHistory} from 'react-router-dom';
import {
  Dialog,
  Divider,
  Template,
  Heading,
  Stack,
  FormItem,
  TextInput,
  Select,
  ISelectOption,
  Grid,
  Cluster,
  Button,
  useValidateForm,
  Click,
  Paragraph,
  Toast,
  trimModel,
} from '@pluto-tv/assemble';
import {ICarouselConfigCreate, IOnClickActionOverrides} from 'models/carouselConfigs';
import {carouselConfigCreateValidator} from 'views/programming/hubManager/carousels/validators';
import {useInsertMutation} from 'features/carouselConfigs/carouselConfigsApi';
import carouselRoutes from 'routes/programming.routes';
import {useUserRegions} from 'helpers/useUserRegions';
import {convertToApiUrl} from 'components/carouselList/helpers/SourceServiceHelper';
import AssociatedCarouselSelect from 'components/carouselList/components/AssociatedCarouselSelect';
import {
  isAssociatedCarouselRequired,
  sourceOptions,
  getServiceOptions,
} from 'components/carouselList/components/helpers/carouselServiceOptions';
import CarouselConfigAutocomplete from 'components/carouselConfigAutocomplete';
import {IHubCarousel} from 'models/hubConfigs';
import {hubCarouselConfigsValidator} from 'views/programming/hubManager/hubs/validators';

interface ICreateCarouselFormProps {
  setCreateOpen: (value: boolean) => void;

  // Props used to Add carousel to hub
  primaryBtnText?: string;
  secondPrimaryBtn?: boolean;
  addCarouselToHub?: boolean;
  addCarousel?: (carouselToAdd: Partial<IHubCarousel>) => void;
  hubCarouselIds?: string[];
  onCreate?: (carouselConfigId: string) => void;
  hubRegion?: string;
}

const carouselNameInputId = 'carouselName';

const CreateCarouselForm = React.memo(
  ({
    setCreateOpen,
    onCreate,
    // Props used to Add carousel to hub
    addCarouselToHub = false,
    primaryBtnText = 'Create',
    secondPrimaryBtn = true,
    addCarousel,
    hubCarouselIds,
    hubRegion,
  }: ICreateCarouselFormProps) => {
    const [isCreating, setIsCreating] = React.useState(false);

    const [insertCarouselConfig] = useInsertMutation();
    const history = useHistory();
    const {activeRegions, isError: isErrorRegions} = useUserRegions();
    const activeRegionsList = React.useMemo(() => activeRegions.map(ac => ac.code), [activeRegions]);

    const {
      form,
      model,
      state: formState,
      onBlur,
      onChange,
      setFields,
      reset: resetCreate,
    } = useValidateForm<ICarouselConfigCreate>(carouselConfigCreateValidator, 'onBlur');

    const {
      form: hubCarouselConfigForm,
      model: hubCarouselConfigModel,
      onBlur: hubCarouselConfigOnBlur,
      onChange: hubCarouselConfigOnChange,
      setFields: hubCarouselConfigSetFields,
      state: hubCarouselConfigFormState,
      reset: hubCarouselConfigReset,
    } = useValidateForm<IHubCarousel>(hubCarouselConfigsValidator, 'immediate');

    const carouselServiceOptions = React.useMemo(() => {
      if (!model.source) return [];
      return getServiceOptions(model.source);
    }, [model.source]);

    const associatedCarouselRequired = React.useMemo(() => {
      if (!model.source || !model.service) return false;
      return isAssociatedCarouselRequired(model.source, model.service);
    }, [model.source, model.service]);

    React.useEffect(() => {
      resetCreate();

      if (!addCarouselToHub) {
        setTimeout(() => {
          const hubNameInput = document.getElementById(carouselNameInputId);
          hubNameInput?.focus({
            preventScroll: true,
          });
        });
      }
    }, [addCarouselToHub, resetCreate]);

    React.useEffect(() => {
      if (isErrorRegions) {
        Toast.error('Error', 'There was an error retrieving the Active Regions.');
      }
    }, [isErrorRegions]);

    React.useEffect(() => {
      if (carouselServiceOptions.length === 1) {
        setFields({service: carouselServiceOptions[0].value});
      }
    }, [carouselServiceOptions, setFields]);

    const handleCreate = async (navigateTo = false): Promise<string | undefined> => {
      let carouselConfigId: string | undefined;

      try {
        setIsCreating(true);

        const apiUrl = convertToApiUrl(model.source || '', model.service, model.associatedCarousel);

        const onClickActionOverridesValue: IOnClickActionOverrides = {};
        if (model.onClickActionOverrides && Array.isArray(model.onClickActionOverrides)) {
          model.onClickActionOverrides.forEach((value: string) => {
            const val = value.split('#');
            onClickActionOverridesValue[val[0] as keyof ['series', 'movie', 'channel']] = val[1] as keyof [
              'playVideo',
              'contentDetails',
            ];
          });
        }

        let apiParams = {};
        switch (model.source) {
          case 'mediacatalog':
            apiParams = {
              mainCategoryId: model.associatedCarousel?.id,
              mainCategoryName: model.associatedCarousel?.name,
            };
            break;
          case 'recommender':
            if (model.service === 'similar-tvshows') {
              apiParams = {
                seriesId: model.associatedCarousel?.id,
                seriesName: model.associatedCarousel?.name,
              };
            } else if (model.service === 'similar-channels') {
              apiParams = {
                channelId: model.associatedCarousel?.id,
                channelName: model.associatedCarousel?.name,
              };
            } else if (model.service === 'similar-movies') {
              apiParams = {
                episodeId: model.associatedCarousel?.id,
                episodeName: model.associatedCarousel?.name,
              };
            }
            break;
          case 'mlcarousels':
            apiParams = {
              carouselId: model.associatedCarousel?.id,
              carouselName: model.associatedCarousel?.name,
            };
            break;
          case 'vod':
            apiParams = {
              vodCategoryId: model.associatedCarousel?.id,
              vodCategoryName: model.associatedCarousel?.name,
            };
            break;

          default:
            break;
        }

        const postModel: Partial<ICarouselConfigCreate> = {
          apiService: `service-${model.source}`,
          apiUrl: apiUrl,
          apis: [
            {
              apiUrl: apiUrl,
              apiService: `${model.source}#${model.service}`,
              apiParams: apiParams,
            },
          ],
          onClickActionOverrides: model.onClickActionOverrides ? onClickActionOverridesValue : undefined,

          name: model.name,
          displayModel: model.displayModel,
          refreshOnUserAction: model.refreshOnUserAction,
          onClickAction: model.onClickAction,
          maxDisplayedTilesCount: model.maxDisplayedTilesCount,
          activeRegion: model.activeRegion || ['ALL'],

          modifier: 'cms_tooling',
          policy: 'append',
        };

        const newCarouselConfig = await insertCarouselConfig(trimModel(postModel, 'name')).unwrap();
        carouselConfigId = newCarouselConfig.id!;

        if (addCarouselToHub && addCarousel) {
          const carousel = {
            id: carouselConfigId,
            displayName: hubCarouselConfigModel?.displayName,
            tileSize: hubCarouselConfigModel?.tileSize || 'normal',
            apiService: `${model.source}#${model.service}`,
          };
          addCarousel(carousel);
        } else {
          const toastMsg = navigateTo ? (
            'Carousel Configuration Created'
          ) : (
            <Stack space='xxsmall'>
              <Paragraph>Carousel Configuration Created</Paragraph>
              <Click
                underline={true}
                hoverColor='white'
                onClick={() =>
                  history.push(carouselRoutes.paths.carouselEditDetailsPage.replace(':id', carouselConfigId as string))
                }
              >
                View Carousel Configuration: {postModel.name}
              </Click>
            </Stack>
          );

          Toast.success('Success', toastMsg, 8000);

          if (navigateTo && carouselConfigId) {
            return carouselConfigId;
          }
        }

        setCreateOpen(false);
        setIsCreating(false);
        onCreate?.(carouselConfigId);
      } catch (e: any) {
        let errorMsg = 'Failed to create Carousel Configuration. Please try again';

        if (e?.data?.validationErrors) {
          errorMsg = Object.values(e.data.validationErrors).join(',');
        } else if (e?.status === 500 && e?.data.message) {
          try {
            const message = JSON.parse(e.data.message)?.message;
            errorMsg = message ? message.charAt(0).toUpperCase() + message.slice(1) : errorMsg;
          } catch (_e) {}
        }

        Toast.error('Error', errorMsg);
        setIsCreating(false);
      }
    };

    const handleCreateAndEdit = async () => {
      const carouselConfigId = await handleCreate(true);

      if (carouselConfigId) {
        history.push(carouselRoutes.paths.carouselEditDetailsPage.replace(':id', carouselConfigId as string));
      }
    };

    const handleCancel = () => {
      setCreateOpen(false);
    };

    const [searchValue, setSearchValue] = React.useState<ISelectOption>();

    const searchPopulated = React.useMemo(() => {
      if (searchValue) {
        resetCreate();
        hubCarouselConfigSetFields({
          id: searchValue.value,
          displayName: searchValue.label.match(/([^()]*)/)?.[0].trim() || searchValue.label,
          apiService: searchValue.apiService || '',
        });
        return true;
      } else {
        hubCarouselConfigReset();
        return false;
      }
    }, [searchValue, resetCreate, hubCarouselConfigSetFields, hubCarouselConfigReset]);

    const activeRegionFiltered = React.useMemo(
      () =>
        model.activeRegion
          ? model.activeRegion[0].toUpperCase() !== 'ALL'
            ? [model.activeRegion[0]]
            : activeRegionsList
          : [],
      [activeRegionsList, model.activeRegion],
    );

    const tilesSection = () => {
      const maxTilesSection = (
        <FormItem
          {...form.maxDisplayedTilesCount}
          onBlur={() => onBlur('maxDisplayedTilesCount')}
          state={searchPopulated ? 'disabled' : form.maxDisplayedTilesCount?.state}
        >
          <TextInput
            placeholder='Max Tiles Displayed'
            type='number'
            onChange={value => {
              onChange('maxDisplayedTilesCount', value);
            }}
            value={model.maxDisplayedTilesCount}
            id='maxTilesDisplayed'
          />
        </FormItem>
      );

      if (addCarouselToHub) {
        return (
          <Grid gap='large' cols={2}>
            <FormItem
              {...hubCarouselConfigForm.tileSize}
              onBlur={() => hubCarouselConfigOnBlur?.('tileSize')}
              state={searchPopulated ? 'disabled' : hubCarouselConfigForm.tileSize?.state}
            >
              <Select
                placeholder='Select Tiles Size'
                predicate='value'
                onChange={value => {
                  hubCarouselConfigSetFields?.({tileSize: (value as ISelectOption)?.value as 'normal' | 'large'});
                }}
                value={
                  searchPopulated
                    ? undefined
                    : ({
                        label: hubCarouselConfigModel.tileSize,
                        value: hubCarouselConfigModel.tileSize,
                      } as ISelectOption)
                }
                id='tileSize'
                options={[
                  {label: 'Normal', value: 'normal'},
                  {label: 'Large', value: 'large'},
                ]}
              />
            </FormItem>
            <>{maxTilesSection}</>
          </Grid>
        );
      }

      return <>{maxTilesSection}</>;
    };

    return (
      <Dialog
        isOpen={true}
        onClose={() => setCreateOpen(false)}
        width='42.8125rem'
        height='51rem'
        id='addCarouselContent'
      >
        <Template label='header'>
          <Heading level='h2'>{addCarouselToHub ? 'Add Carousel' : 'Create Carousel'}</Heading>
        </Template>
        <Template label='body'>
          <Stack space='xxxlarge'>
            {addCarouselToHub && hubRegion && (
              <Stack space='xxlarge'>
                <Stack space='large'>
                  <Paragraph color='primary'>Search to find an existing carousel, or create a new carousel.</Paragraph>
                  <Stack space='small'>
                    <FormItem label='Search Carousel Configurations'>
                      <CarouselConfigAutocomplete
                        onSelect={(value?: ISelectOption) => setSearchValue(value)}
                        activeRegion={hubRegion}
                        disabledIds={hubCarouselIds || []}
                      />
                    </FormItem>
                    <Grid gap='xlarge'>
                      <FormItem
                        {...hubCarouselConfigForm.displayName}
                        onBlur={() => hubCarouselConfigOnBlur?.('displayName')}
                        state={!searchPopulated ? 'disabled' : hubCarouselConfigForm.displayName?.state}
                      >
                        <TextInput
                          placeholder='Display Name'
                          onChange={value => {
                            hubCarouselConfigOnChange?.('displayName', value);
                          }}
                          value={searchValue ? hubCarouselConfigModel?.displayName : ''}
                          id='carouselDisplayNameAdd'
                        />
                      </FormItem>
                      <FormItem
                        {...hubCarouselConfigForm.tileSize}
                        onBlur={() => hubCarouselConfigOnBlur?.('tileSize')}
                        state={!searchPopulated ? 'disabled' : hubCarouselConfigForm.tileSize?.state}
                      >
                        <Select
                          placeholder='Select Tiles Size'
                          predicate='value'
                          onChange={value => {
                            hubCarouselConfigSetFields?.({
                              tileSize: (value as ISelectOption)?.value as 'normal' | 'large',
                            });
                          }}
                          value={
                            searchValue
                              ? ({
                                  label: hubCarouselConfigModel.tileSize,
                                  value: hubCarouselConfigModel.tileSize,
                                } as ISelectOption)
                              : undefined
                          }
                          id='tileSize'
                          options={[
                            {label: 'Normal', value: 'normal'},
                            {label: 'Large', value: 'large'},
                          ]}
                        />
                      </FormItem>
                    </Grid>
                  </Stack>
                </Stack>
                <Divider color='neutral' textMessage='OR' textBackgroundColor='pewter' textMessageColor='secondary' />
              </Stack>
            )}
            <form
              id='createCarouselContent'
              onSubmit={ev => {
                ev.preventDefault();
              }}
            >
              <Stack space='small'>
                <FormItem
                  {...form.name}
                  onBlur={() => onBlur('name')}
                  state={searchPopulated ? 'disabled' : form.name?.state}
                >
                  <TextInput
                    placeholder='Carousel Name'
                    onChange={value => {
                      onChange('name', value);
                    }}
                    value={model.name}
                    id={carouselNameInputId}
                  />
                </FormItem>
                {addCarouselToHub && (
                  <FormItem
                    {...hubCarouselConfigForm.displayName}
                    onBlur={() => hubCarouselConfigOnBlur?.('displayName')}
                    state={searchPopulated ? 'disabled' : hubCarouselConfigForm.displayName?.state}
                  >
                    <TextInput
                      placeholder='Display Name'
                      onChange={value => {
                        hubCarouselConfigOnChange?.('displayName', value);
                      }}
                      value={searchPopulated ? '' : hubCarouselConfigModel?.displayName}
                      id='carouselDisplayNameCreate'
                    />
                  </FormItem>
                )}
                <FormItem
                  {...form?.activeRegion}
                  state={searchPopulated ? 'disabled' : form.activeRegion?.state}
                  onBlur={() => onBlur('activeRegion')}
                >
                  <Select
                    placeholder='Select Active Region'
                    onChange={value => {
                      setFields({
                        activeRegion: [(value as ISelectOption).value],
                        associatedCarousel: undefined,
                      });
                    }}
                    value={{label: model.activeRegion?.[0], value: model.activeRegion?.[0]} as ISelectOption}
                    id='activeRegion'
                    appendToBody={true}
                    predicate='value'
                    options={[
                      {label: 'ALL', value: 'ALL'},
                      ...activeRegions.map(ar => ({
                        label: `${ar.name} (${ar.code})`,
                        value: ar.code.toUpperCase(),
                      })),
                    ]}
                  />
                </FormItem>
                <FormItem
                  {...form.displayModel}
                  onBlur={() => onBlur('displayModel')}
                  state={searchPopulated ? 'disabled' : form.displayModel?.state}
                >
                  <Select
                    id='carouselDisplayModel'
                    appendToBody={true}
                    placeholder='Select Display Model'
                    predicate='value'
                    clearable={true}
                    value={{value: model.displayModel, label: ''}}
                    options={[
                      {label: 'Basic', value: 'basic'},
                      {label: 'Categories', value: 'categories'},
                      {label: 'Featured', value: 'featured'},
                      {label: 'Poster', value: 'poster'},
                      {label: 'Resume Point', value: 'resumePoint'},
                    ]}
                    onChange={value => {
                      const displayModel = (value as ISelectOption)?.value;
                      setFields({
                        displayModel,
                      });
                    }}
                  />
                </FormItem>
                {tilesSection()}

                <Grid gap='large' cols={2}>
                  <FormItem
                    {...form.source}
                    onBlur={() => onBlur('source')}
                    state={searchPopulated || !model.activeRegion ? 'disabled' : form.source?.state}
                  >
                    <Select
                      id='carouselSource'
                      appendToBody={true}
                      placeholder='Select Carousel Source'
                      predicate='value'
                      value={{value: model.source, label: ''}}
                      options={sourceOptions}
                      onChange={value => {
                        const source = (value as ISelectOption)?.value;
                        setFields({
                          source,
                          service: '',
                          associatedCarousel: undefined,
                        });
                      }}
                    />
                  </FormItem>
                  <FormItem
                    {...form.service}
                    onBlur={() => onBlur('service')}
                    state={searchPopulated || !model.activeRegion ? 'disabled' : form.service?.state}
                  >
                    <Select
                      id='carouselService'
                      appendToBody={true}
                      placeholder='Select Carousel Service'
                      predicate='value'
                      value={{value: model.service, label: ''}}
                      options={carouselServiceOptions}
                      onChange={value => {
                        const service = (value as ISelectOption)?.value;
                        setFields({
                          service,
                          associatedCarousel: undefined,
                        });
                      }}
                    />
                  </FormItem>
                </Grid>
                <FormItem
                  {...form.associatedCarousel}
                  required={associatedCarouselRequired}
                  onBlur={() => onBlur('associatedCarousel')}
                  state={
                    associatedCarouselRequired && !model.associatedCarousel
                      ? 'error'
                      : !associatedCarouselRequired
                      ? 'disabled'
                      : 'normal'
                  }
                  helpText={
                    associatedCarouselRequired && !model.associatedCarousel?.id
                      ? 'Your Source and/or Service selections make this field required'
                      : ''
                  }
                  helpTextColor='error'
                >
                  <AssociatedCarouselSelect
                    source={model.source || ''}
                    service={model.service || ''}
                    associatedCarousel={model.associatedCarousel}
                    activeRegion={model.activeRegion?.[0] || ''}
                    onChange={val => {
                      if (!val) return;
                      setFields({associatedCarousel: {name: val.label, id: val.value}});
                    }}
                    activeRegionsList={activeRegionFiltered}
                    isError={isErrorRegions}
                  />
                </FormItem>
                <FormItem
                  {...form.onClickAction}
                  onBlur={() => onBlur('onClickAction')}
                  state={searchPopulated ? 'disabled' : form.onClickAction?.state}
                >
                  <Select
                    id='onClickAction'
                    placeholder='Select On Click Action'
                    predicate='value'
                    clearable={true}
                    value={{value: model.onClickAction, label: ''}}
                    options={[
                      {label: 'Play Video', value: 'playVideo'},
                      {label: 'Content Details', value: 'contentDetails'},
                      {label: 'Load Hub', value: 'loadHub'},
                    ]}
                    onChange={value => {
                      const onClickAction = (value as ISelectOption)?.value;
                      setFields({
                        onClickAction,
                      });
                    }}
                  />
                </FormItem>
                <FormItem
                  {...form.onClickActionOverrides}
                  onBlur={() => onBlur('onClickActionOverrides')}
                  state={searchPopulated ? 'disabled' : form.onClickActionOverrides?.state}
                >
                  <Select
                    id='onClickActionOverride'
                    placeholder='Select On Click Action Override'
                    predicate='value'
                    multiselect={true}
                    clearable={true}
                    value={
                      model.onClickActionOverrides && Array.isArray(model.onClickActionOverrides)
                        ? model.onClickActionOverrides?.map(val => ({
                            label: '',
                            value: val,
                          }))
                        : undefined
                    }
                    options={[
                      {
                        label: 'Channel - Play Video',
                        value: 'channel#playVideo',
                        disabled:
                          Array.isArray(model.onClickActionOverrides) &&
                          model.onClickActionOverrides.includes('channel#contentDetails'),
                      },
                      {
                        label: 'Channel - Content Details',
                        value: 'channel#contentDetails',
                        disabled:
                          Array.isArray(model.onClickActionOverrides) &&
                          model.onClickActionOverrides.includes('channel#playVideo'),
                      },
                      {
                        label: 'Series - Play Video',
                        value: 'series#playVideo',
                        disabled:
                          Array.isArray(model.onClickActionOverrides) &&
                          model.onClickActionOverrides.includes('series#contentDetails'),
                      },
                      {
                        label: 'Series - Content Details',
                        value: 'series#contentDetails',
                        disabled:
                          Array.isArray(model.onClickActionOverrides) &&
                          model.onClickActionOverrides.includes('series#playVideo'),
                      },
                      {
                        label: 'Movie - Play Video',
                        value: 'movie#playVideo',
                        disabled:
                          Array.isArray(model.onClickActionOverrides) &&
                          model.onClickActionOverrides.includes('movie#contentDetails'),
                      },
                      {
                        label: 'Movie - Content Details',
                        value: 'movie#contentDetails',
                        disabled:
                          Array.isArray(model.onClickActionOverrides) &&
                          model.onClickActionOverrides.includes('movie#playVideo'),
                      },
                    ]}
                    onChange={value =>
                      setFields({
                        onClickActionOverrides: (value as ISelectOption[])?.map(ar => ar.value),
                      })
                    }
                  />
                </FormItem>
                <FormItem
                  {...form.refreshOnUserAction}
                  onBlur={() => onBlur('refreshOnUserAction')}
                  state={searchPopulated ? 'disabled' : form.refreshOnUserAction?.state}
                >
                  <Select
                    id='refreshOnUserAction'
                    placeholder='Select Refresh On User Action'
                    predicate='value'
                    multiselect={true}
                    clearable={true}
                    value={model.refreshOnUserAction?.map(val => ({
                      label: '',
                      value: val,
                    }))}
                    options={[
                      {label: 'Favorites Changed', value: 'favoritesChanged'},
                      {label: 'Watchlist Changed', value: 'watchlistChanged'},
                      {label: 'Consumption Changed', value: 'consumptionChanged'},
                    ]}
                    onChange={value =>
                      setFields({
                        refreshOnUserAction: (value as ISelectOption[])?.map(ar => ar.value),
                      })
                    }
                  />
                </FormItem>
              </Stack>
            </form>
          </Stack>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} onClick={handleCancel} id='cancelButton'>
                Cancel
              </Button>
              {searchValue && (
                <Button
                  type='primary'
                  onClick={() => {
                    if (addCarousel) {
                      addCarousel(hubCarouselConfigModel);
                      setCreateOpen(false);
                    }
                  }}
                  id='createButton'
                  state={!hubCarouselConfigFormState.isDirty || !hubCarouselConfigFormState.isValid ? 'disabled' : ''}
                >
                  {primaryBtnText}
                </Button>
              )}
              {!searchValue && (
                <Button
                  type='primary'
                  state={
                    !formState.isValid ||
                    !formState.isDirty ||
                    (associatedCarouselRequired && !model.associatedCarousel) ||
                    (addCarouselToHub && (!hubCarouselConfigFormState?.isValid || !hubCarouselConfigFormState?.isDirty))
                      ? 'disabled'
                      : isCreating
                      ? 'thinking'
                      : ''
                  }
                  onClick={() => handleCreate()}
                  id='createButton'
                >
                  {primaryBtnText}
                </Button>
              )}
              {secondPrimaryBtn && (
                <Button
                  type='primary'
                  state={
                    !formState.isValid ||
                    !formState.isDirty ||
                    (associatedCarouselRequired && !model.associatedCarousel)
                      ? 'disabled'
                      : isCreating
                      ? 'thinking'
                      : ''
                  }
                  onClick={handleCreateAndEdit}
                  id='createEditButton'
                >
                  Create and Edit
                </Button>
              )}
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    );
  },
);

CreateCarouselForm.displayName = 'CreateCarouselForm';
export default CreateCarouselForm;
