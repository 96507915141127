import * as React from 'react';
import {Box, Center, Cover, Stack, Template, PlutoLogin, Toasts, Toast} from '@pluto-tv/assemble';

import {useAppDispatch} from 'app/store/hooks';
import {useAuthenticateMutation} from 'features/authentication/authenticationApi';
import {login, logout} from 'features/user/userSlice';
import PlutoTvLogo from 'assets/plutoTVLogo';

export default (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [authenticate] = useAuthenticateMutation();

  const onSuccess = async ({access_token}: {access_token: string}) => {
    try {
      const authenticationResult = await authenticate(access_token).unwrap();
      dispatch(login(authenticationResult));
    } catch (err) {
      Toast.error((err as any)?.data.message || 'Unable to login. Try again later.');
    }
  };

  const onFailure = () => {
    dispatch(logout());
  };

  const handleGoogleLoginSuccess = response => {
    if ('access_token' in response) {
      onSuccess(response);
    }
  };

  return (
    <Box background='slate'>
      <Cover center={true}>
        <Template label='cover'>
          <Center textCenter={true}>
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                width: '37.5rem',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <Stack space='xxlarge' alignItems='center'>
                <PlutoTvLogo />
                <PlutoLogin onSuccess={handleGoogleLoginSuccess} onError={onFailure} />
              </Stack>
            </div>
          </Center>
        </Template>
      </Cover>
      <Toasts />
    </Box>
  );
};
