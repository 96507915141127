import * as React from 'react';
import {
  Expand,
  Template,
  Box,
  Cover,
  Stack,
  Cluster,
  Icon,
  Heading,
  Divider,
  FormItem,
  TextInput,
  Button,
  Select,
  TagList,
  useValidateForm,
  Checkbox,
  DateTime,
  IDateRange,
  ISelectOption,
} from '@pluto-tv/assemble';
import {useUserRegions} from 'helpers/useUserRegions';
import {TSortDirection} from 'models/generic';
import {IListSeriesQuery} from 'models/series';
import {IUserSeriesSearch} from 'models/users';
import {useFindQuery as useFindGenresQuery} from 'features/genres/genresApi';
import {useFindQuery as useFindLanguagesQuery} from 'features/languages/languagesApi';
import {useFindQuery as useFindCategoriesQuery} from 'features/categories/categoriesApi';
import {useUserRatings} from 'helpers/useUserRatings';
import {orderBy} from 'lodash-es';
import useToggleSearchBarOnSlash from 'helpers/useToggleSearchBarOnSlash';

export interface AddContentVodCollectionSearchBarProps {
  sortDir: TSortDirection;
  sortCol: string;
  isModalOpen?: boolean;
  isSearchExpanded: boolean;
  publishedState?: boolean;
  searchRegion?: string;
  isFetching: boolean;
  search?: IUserSeriesSearch;
  hideActiveRegions?: boolean;
  canViewOO: boolean;
  canViewPartner: boolean;
  setIsSearchExpanded: (isExpanded: boolean) => void;
  onSearch: (searchParams: IUserSeriesSearch) => void;
  onClear: () => void;
}

const seriesTitleInputId = 'seriesTitle';

const VodCollectionProgramTabSeriesSearchBar = React.memo(
  ({
    sortDir,
    sortCol,
    isModalOpen,
    isSearchExpanded,
    publishedState,
    searchRegion,
    isFetching,
    search,
    hideActiveRegions,
    canViewOO,
    canViewPartner,
    setIsSearchExpanded,
    onSearch,
    onClear,
  }: AddContentVodCollectionSearchBarProps) => {
    const {
      model: searchModel,
      onChange: searchOnChange,
      setFields: searchSetFields,
      setModel: searchSetModel,
      form: searchForm,
      getValidation: searchGetValidation,
      onBlur: searchOnBlur,
    } = useValidateForm<IListSeriesQuery>(
      [
        {name: 'published', label: 'Published'},
        {name: 'type', label: 'Series Type'},
        {name: 'name', label: 'Series Name'},
        {name: 'tags', label: 'Tags'},
        {name: 'genre', label: 'Genre'},
        {name: 'subGenre', label: 'Sub-Genre'},
        {name: 'category', label: 'Category'},
        {name: 'subCategory', label: 'Sub-Category'},
        {name: 'avodWindowMode', label: 'AVOD Avails Window'},
        {name: 'avodWindowType', label: 'AVOD Avails Window'},
        {name: 'avodWindow'},
        !hideActiveRegions ? {name: 'activeRegions', label: 'Active Region'} : (false as any),
        {name: 'rating', label: 'Rating'},
        {name: 'plutoTvOO', label: 'Pluto TV O&O'},
        {name: 'metadataLanguage', label: 'Language'},
        {name: 'minCreatedAt'},
        {name: 'maxCreatedAt'},
        {name: 'createdAt'},
        {name: 'minUpdatedAt'},
        {name: 'maxUpdatedAt'},
      ].filter(Boolean),
      'ask',
    );

    const [subgenresOptions, setSubgenresOptions] = React.useState<ISelectOption[]>([]);
    const [subCategoriesOptions, setSubCategoriesOptions] = React.useState<ISelectOption[]>([]);
    const [isPublishToggled, setIsPublishToggled] = React.useState<boolean>(false);
    const seriesTypes = ['tv', 'film'];

    const defaultPlutoTvOOVal = React.useMemo((): boolean | undefined => {
      if (canViewOO && canViewPartner) {
        return searchModel?.plutoTvOO;
      } else if (!canViewOO) {
        return false;
      } else {
        return true;
      }
    }, [searchModel, canViewOO, canViewPartner]);

    const {contentRatings} = useUserRatings();
    useToggleSearchBarOnSlash(setIsSearchExpanded, isSearchExpanded);

    const {
      activeRegions,
      //isFetching: isUserRegionsFetching,
      //isError: isUserRegionsError,
    } = useUserRegions();
    const {
      data: genres,
      //isFetching: isFetchingGenres,
      //isError: isErrorGenres
    } = useFindGenresQuery();
    const {
      data: languages,
      //isFetching: isFetchingLanguages,
      //isError: isErrorLanguages
    } = useFindLanguagesQuery();

    const {data: categories} = useFindCategoriesQuery();

    const handleSearch = async () => {
      const validation = await searchGetValidation();

      if (!validation.state.isValid) {
        return;
      }

      onSearch({
        name: '',
        model: validation.model,
        sortCol: sortCol,
        sortDir: sortDir,
      });
    };

    React.useEffect(() => {
      if (!isModalOpen) {
        onClear();
        searchSetModel({});
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    React.useEffect(() => {
      if (search) {
        searchSetModel(search.model);
        if (publishedState && !isPublishToggled) {
          searchSetFields({published: publishedState});
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, searchSetModel]);

    const clearHandler = () => {
      onClear();
      searchSetModel({});
      setIsPublishToggled(true);
    };

    const handlePublishToggle = val => {
      searchOnChange('published', val);
      setIsPublishToggled(true);
    };

    React.useEffect(() => {
      if (isSearchExpanded) {
        setTimeout(() => {
          const seriesTitleInput = document.getElementById(seriesTitleInputId);
          seriesTitleInput?.focus({
            preventScroll: true,
          });
        });
      }
    }, [isSearchExpanded]);

    React.useEffect(() => {
      if (searchModel.genre && genres?.length) {
        setSubgenresOptions(
          orderBy(
            genres.find(g => g.genre === searchModel.genre)?.subGenres.map(subg => ({label: subg})) || [],
            'label',
          ),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchModel.genre, genres]);

    React.useEffect(() => {
      if (searchModel.category && categories?.length) {
        setSubCategoriesOptions(
          orderBy(
            categories.find(g => g.category === searchModel.category)?.subCategories.map(subc => ({label: subc})) || [],
            'label',
          ),
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchModel.category, categories]);

    const ratingsForSelectedRegions = React.useMemo(() => {
      const selectedRegions = searchModel.activeRegions || [searchRegion];

      if (selectedRegions.length === 0) {
        return contentRatings;
      }

      const newContentRatings = contentRatings.filter(r => r.group === '' || selectedRegions.includes(r.group || ''));

      if (searchModel.rating && !newContentRatings.some(c => c.label === searchModel.rating)) {
        searchSetFields({rating: undefined});
      }

      return newContentRatings;
    }, [searchModel.activeRegions, searchRegion, contentRatings, searchModel.rating, searchSetFields]);

    return (
      <Expand width='18.75rem' height='100%' fullHeightContainer={true} isExpanded={isSearchExpanded}>
        <Template label='expandable'>
          <Box background='pewter' paddingRight='medium' fullHeight={true}>
            <Cover scrolling={true} gutter='medium'>
              <Template label='header'>
                <Stack space='medium'>
                  <Cluster align='center' justify='space-between'>
                    <Icon icon='tune' space='small' size='large' iconAlign='center'>
                      <Heading level='h4'>Search Filters</Heading>
                    </Icon>
                    <Icon icon='collapseleft' size='large' onClick={() => setIsSearchExpanded(!isSearchExpanded)} />
                  </Cluster>
                  <Divider color='graphite' />
                  <Checkbox
                    label='Published'
                    value={searchModel.published}
                    onChange={val => handlePublishToggle(val)}
                  />

                  <Divider color='graphite' />
                </Stack>
              </Template>
              <Template label='cover'>
                <form
                  onSubmit={ev => {
                    ev.preventDefault();
                    setTimeout(() => handleSearch());
                  }}
                >
                  <Stack space='small'>
                    {/* Using this to allow pressing enter to submit form */}
                    <input type='submit' style={{display: 'none'}} />
                    <Stack space='small'>
                      <FormItem {...searchForm.type}>
                        <Select
                          id='type'
                          clearable={true}
                          placeholder='Select Type'
                          predicate='value'
                          value={{label: searchModel.type || '', value: searchModel.type}}
                          onChange={val => searchSetFields({type: val?.value})}
                          options={[
                            {label: 'Film', value: 'film'},
                            {label: 'TV', value: 'tv'},
                            {label: 'Web Original', value: 'web-original'},
                            {label: 'Music Video', value: 'music-video'},
                            {label: 'Live', value: 'live'},
                          ].filter(t => seriesTypes.includes(t.value))}
                        />
                      </FormItem>
                      <FormItem
                        {...searchForm.name}
                        onBlur={() => {
                          searchOnBlur('name');
                        }}
                      >
                        <TextInput
                          id={seriesTitleInputId}
                          placeholder='Title'
                          value={searchModel.name}
                          onChange={val => {
                            searchOnChange('name', val);
                          }}
                        />
                      </FormItem>
                      <FormItem {...searchForm.tags}>
                        <TagList
                          placeholder='Select Tags'
                          value={searchModel.tags}
                          onChange={value => searchSetFields({tags: value})}
                        />
                      </FormItem>
                    </Stack>
                    <FormItem {...searchForm.genre} onBlur={() => searchOnBlur('genre', false)}>
                      <Select
                        clearable={true}
                        predicate='value'
                        placeholder='Select Genre'
                        onChange={value =>
                          searchSetFields({
                            genre: value?.label,
                            genres: !value ? [] : [value?.label],
                            subGenre: undefined,
                            subGenres: undefined,
                          })
                        }
                        value={{value: searchModel.genre || '', label: searchModel.genre || ''}}
                        id='genre'
                        options={(genres || []).map(g => ({label: g.genre, value: g.genre}))}
                        searchable={true}
                        searchPlaceholder='Search for genre'
                        onSearch={val =>
                          orderBy(
                            (genres || [])
                              .filter(g => g.genre.toLowerCase().indexOf(val.toLowerCase()) > -1)
                              .map(g => ({label: g.genre, value: g.genre}), 'label'),
                          ) || []
                        }
                      />
                    </FormItem>
                    <FormItem
                      {...searchForm.subGenre}
                      state={!searchModel.genre ? 'disabled' : ''}
                      onBlur={() => searchOnBlur('subGenre', false)}
                    >
                      <Select
                        id='subGenre'
                        clearable={true}
                        options={subgenresOptions}
                        onChange={value =>
                          searchSetFields({
                            subGenre: value?.label,
                            subGenres: !value ? undefined : [value?.label],
                          })
                        }
                        value={{value: searchModel.subGenre || '', label: searchModel.subGenre || ''}}
                        placeholder='Select Sub-Genre'
                        searchable={true}
                        searchPlaceholder='Search for sub-genre'
                        onSearch={val =>
                          orderBy(
                            (subgenresOptions || [])
                              .filter(g => g.label.toLowerCase().indexOf(val.toLowerCase()) > -1)
                              .map(g => ({label: g.label, value: g.value}), 'label'),
                          ) || []
                        }
                      />
                    </FormItem>
                    <FormItem {...searchForm.category} onBlur={() => searchOnBlur('category', false)}>
                      <Select
                        clearable={true}
                        placeholder='Select Category'
                        onChange={value =>
                          searchSetFields({
                            category: value?.label,
                            categories: !value ? undefined : [value?.label],
                            subCategory: undefined,
                            subCategories: undefined,
                          })
                        }
                        value={{value: searchModel.category || '', label: searchModel.category || ''}}
                        id='category'
                        options={orderBy(
                          (categories || []).map(category => ({label: category.category})),
                          'label',
                        )}
                        searchable={true}
                        searchPlaceholder='Search for category'
                        onSearch={val =>
                          orderBy(
                            (categories || [])
                              .filter(item => item.category.toLowerCase().indexOf(val.toLowerCase()) > -1)
                              .map(item => ({label: item.category}), 'label'),
                          ) || []
                        }
                      />
                    </FormItem>
                    <FormItem
                      {...searchForm.subCategory}
                      state={!searchModel.category ? 'disabled' : ''}
                      onBlur={() => searchOnBlur('subCategory', false)}
                    >
                      <Select
                        id='subCategory'
                        clearable={true}
                        options={subCategoriesOptions}
                        onChange={value =>
                          searchSetFields({
                            subCategory: value?.label,
                            subCategories: !value ? undefined : [value?.label],
                          })
                        }
                        placeholder='Select Sub-Category'
                        value={{value: searchModel.subCategory || '', label: searchModel.subCategory || ''}}
                        searchable={true}
                        searchPlaceholder='Search for sub-category'
                        onSearch={val =>
                          orderBy(
                            (subCategoriesOptions || [])
                              .filter(item => item.label.toLowerCase().indexOf(val.toLowerCase()) > -1)
                              .map(item => ({label: item.label, value: item.value}), 'label'),
                          ) || []
                        }
                      />
                    </FormItem>
                    <Stack>
                      <FormItem {...searchForm.avodWindowType}>
                        <Select
                          id='avodWindowType'
                          placeholder='Select Avails Window'
                          value={{
                            label: searchModel.avodWindowType || '',
                            value: searchModel.avodWindowType || '',
                          }}
                          predicate='value'
                          clearable={true}
                          onChange={val => {
                            searchSetFields({avodWindowType: val?.value || undefined});
                            if (!val?.value) {
                              searchSetFields({
                                avodWindow: undefined,
                                avodWindowWithin: undefined,
                                avodWindowStartDate: undefined,
                                avodWindowEndDate: undefined,
                              });
                            }
                          }}
                          options={[
                            {label: 'Start Date is Between', value: 'startDateBetween'},
                            {label: 'End Date is Between', value: 'endDateBetween'},
                            {label: 'Availability is Within', value: 'availabilityIsWithin'},
                          ]}
                        />
                      </FormItem>
                      <FormItem state={!searchModel?.avodWindowType ? 'disabled' : ''}>
                        <DateTime
                          id='avodWindow'
                          range={true}
                          value={
                            searchModel?.avodWindowWithin?.start && searchModel?.avodWindowWithin?.end
                              ? {
                                  start: new Date(searchModel.avodWindowWithin.start),
                                  end: new Date(searchModel.avodWindowWithin.end),
                                }
                              : undefined
                          }
                          clearable={true}
                          appendToBody={true}
                          onChange={value => {
                            if (value) {
                              const period = value as IDateRange;
                              period.start.setHours(0, 0, 0, 0);
                              period.end?.setHours(23, 59, 59, 999);
                              searchSetFields({
                                avodWindow: (period.start || period.end).toISOString(),
                                avodWindowWithin: {start: period.start.toISOString(), end: period.end?.toISOString()},
                                avodWindowStartDate: period.start.toISOString(),
                                avodWindowEndDate: (period.end || period.start).toISOString(),
                              });
                            } else {
                              searchSetFields({
                                avodWindow: undefined,
                                avodWindowWithin: undefined,
                                avodWindowStartDate: undefined,
                                avodWindowEndDate: undefined,
                              });
                            }
                          }}
                        />
                      </FormItem>
                    </Stack>
                    {!hideActiveRegions && (
                      <FormItem label='Active Region'>
                        <Select
                          multiselect={true}
                          placeholder='Select Active Region'
                          clearable={true}
                          id='targetRegion'
                          value={searchModel.activeRegions?.map(v => ({label: v, value: v})) || []}
                          options={activeRegions.map(ar => ({
                            label: `${ar.name} (${ar.code})`,
                            value: ar.code,
                          }))}
                          onChange={value => {
                            if (!value) {
                              searchSetFields({activeRegions: []});
                            } else {
                              searchSetFields({activeRegions: value.map(v => v.value)});
                            }
                          }}
                          predicate='value'
                        />
                      </FormItem>
                    )}
                    <FormItem label='Rating'>
                      <Select
                        placeholder='Select rating'
                        clearable={true}
                        onChange={value => {
                          if (!value) {
                            searchSetFields({rating: undefined});
                          } else {
                            searchSetFields({rating: value.label});
                          }
                        }}
                        value={{label: searchModel.rating || ''}}
                        id='rating'
                        sortField='weight'
                        options={ratingsForSelectedRegions}
                      />
                    </FormItem>
                    <FormItem label='Pluto TV O&O' permission={canViewOO && canViewPartner ? 'enabled' : 'disabled'}>
                      <Select
                        clearable={true}
                        predicate='value'
                        placeholder='Select Pluto TV O&O'
                        options={[
                          {label: 'Yes', value: true},
                          {label: 'No', value: false},
                        ]}
                        value={{label: '', value: defaultPlutoTvOOVal}}
                        onChange={value => searchSetFields({plutoTvOO: value?.value})}
                      />
                    </FormItem>
                    <FormItem label='Language'>
                      <Select
                        placeholder='Select Language'
                        multiselect={true}
                        onChange={value => {
                          if (!value) {
                            searchSetFields({metadataLanguage: []});
                          } else {
                            searchSetFields({metadataLanguage: value?.map(v => v.value)});
                          }
                        }}
                        value={searchModel.metadataLanguage?.map(mdl => ({
                          label: mdl || '',
                          value: mdl,
                        }))}
                        id='metadataLanguage'
                        predicate='value'
                        clearable={true}
                        searchable={true}
                        searchPlaceholder='Search for language'
                        onSearch={val =>
                          orderBy(
                            (languages || [])
                              .filter(language => language.name.toLowerCase().indexOf(val.toLowerCase()) > -1)
                              .map(language => ({label: language.name, value: language.iso639_1}), 'label'),
                          ) || []
                        }
                        options={orderBy(
                          (languages || []).map(language => ({label: language.name, value: language.iso639_1})),
                          'label',
                        )}
                      />
                    </FormItem>

                    <FormItem label='Created At' state={searchModel?.createdAt ? 'disabled' : ''}>
                      <DateTime
                        id='createdAt'
                        range={true}
                        placeholder='Search by upload date range'
                        value={
                          searchModel.minCreatedAt && searchModel.maxCreatedAt
                            ? {
                                start: new Date(searchModel.minCreatedAt),
                                end: new Date(searchModel.maxCreatedAt),
                              }
                            : undefined
                        }
                        clearable={true}
                        appendToBody={true}
                        onChange={value => {
                          if (!value) {
                            searchSetFields({
                              minCreatedAt: undefined,
                              maxCreatedAt: undefined,
                            });
                          } else {
                            const period = value as IDateRange;
                            period.start.setHours(0, 0, 0, 0);
                            period.end?.setHours(23, 59, 59, 999);
                            searchSetFields({
                              minCreatedAt: period.start.toISOString(),
                              maxCreatedAt: (period.end || period.start).toISOString(),
                            });
                          }
                        }}
                      />
                    </FormItem>
                  </Stack>
                </form>
              </Template>
              <Template label='footer'>
                <Cluster justify='space-between'>
                  <div></div>
                  <Cluster space='small'>
                    <Button ghost={true} state={isFetching ? 'disabled' : ''} onClick={clearHandler}>
                      Clear
                    </Button>
                    <Button type='primary' state={isFetching ? 'thinking' : ''} onClick={handleSearch}>
                      Search
                    </Button>
                  </Cluster>
                </Cluster>
              </Template>
            </Cover>
          </Box>
        </Template>
      </Expand>
    );
  },
);

VodCollectionProgramTabSeriesSearchBar.displayName = 'vodCollectionAddSeriesSearchBar';
export default VodCollectionProgramTabSeriesSearchBar;
