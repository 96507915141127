import * as React from 'react';
import {useAppDispatch} from 'app/store/hooks';
import {useFindLibraryQuery, util} from 'features/channelCatalog/channelCatalogApi';
import {IChannelCatalogItem, IChannelCatalogParams, IChannelCatalogQuery} from 'models/channelCatalog';

type ChannelCatalogLibraryReturn = {
  totalItems: number;
  isError: boolean;
  isLazyLoading: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  libraryItems: IChannelCatalogItem[];
  handleLazyLoad: () => void;
  handleSorting: (sort: string) => void;
  fetch: (searchParams?: Partial<IChannelCatalogQuery>) => void;
  refetch: (pristine: boolean) => void;
  sort: string;
  removeItems: (fromIndex: number[], items: IChannelCatalogItem[]) => void;
};

const defaultParams: IChannelCatalogParams = {
  sort: ['series.name:asc', 'season:asc', 'number:asc'],
  offset: 0,
  limit: 50,
};

const getSortString = (sort: string = defaultParams.sort.join('&') as string) => {
  return sort.split('&')[0];
};
const getSplittedSort = (sort: string) => {
  const splitedSort = sort.split(':');
  return splitedSort;
};

export const useChannelCatalogLibrary = (
  newQueueItems: IChannelCatalogItem[],
  isSearchActive: boolean,
  channelId?: string,
): ChannelCatalogLibraryReturn => {
  const dispatch = useAppDispatch();
  const [libraryItems, setLibraryItems] = React.useState<IChannelCatalogItem[]>([]);
  const [loadingFirstPage, setLoadingFirstPage] = React.useState<boolean>(true);
  const [itemsExistingInSavedLibrary, setItemsExistingInSavedLibrary] = React.useState<Set<string>>(new Set());
  const [params, setParams] = React.useState<Partial<IChannelCatalogQuery>>(defaultParams);

  // this ref stores the previous search params to be used in lazy loading
  const searchParamsRef = React.useRef<Partial<IChannelCatalogQuery>>(defaultParams);

  const {currentData, data, isError, isFetching, isSuccess, error} = useFindLibraryQuery(
    {channelId: channelId || '', ...params},
    {skip: !channelId, refetchOnMountOrArgChange: true},
  );

  React.useEffect(() => {
    if (isError && (error as {status: number})?.status === 404) {
      setLibraryItems([]);
    }
  }, [error, isError, setLibraryItems]);

  React.useEffect(() => {
    if (currentData && !isError) {
      setLibraryItems(prevItems =>
        currentData.metadata.offset === 0 ? currentData.data : [...prevItems, ...currentData.data],
      );
    }
  }, [currentData, isError]);

  const fetch = React.useCallback(
    async (searchParams?: Partial<IChannelCatalogQuery>) => {
      setLoadingFirstPage(true);
      searchParamsRef.current = searchParams || {};
      await dispatch(util.resetApiState());
      setParams({...defaultParams, ...searchParams});
    },
    [dispatch],
  );

  const refetch = React.useCallback(async () => {
    await dispatch(util.invalidateTags(['ChannelCatalog', 'ChannelCatalogLibrary']));
  }, [dispatch]);

  const handleLazyLoad = React.useCallback(() => {
    if (!currentData || isFetching) return;

    const {offset, limit, totalCount, sort} = currentData.metadata;
    const totalRetrieved = error && (error as {status: number})?.status === 404 ? 0 : totalCount || 0;

    const sortArray = sort ? sort.split('&') : undefined;

    if (totalRetrieved > limit + offset) {
      setLoadingFirstPage(false);
      setParams({
        ...defaultParams,
        ...searchParamsRef.current,
        offset: offset + limit,
        sort: sortArray as `${string}:${string}`[],
      });
    }
  }, [currentData, error, isFetching]);

  const handleSorting = React.useCallback(
    (sortField: string) => {
      if (!channelId || libraryItems.length < 2) return;

      setLoadingFirstPage(true);

      const [PrevSortField, prevSortDirection] = getSplittedSort(getSortString(currentData?.metadata.sort));
      const sortDirection = PrevSortField === sortField && prevSortDirection === 'asc' ? 'dsc' : 'asc';

      setParams({
        ...defaultParams,
        ...searchParamsRef.current,
        sort: [`${sortField}:${sortDirection}`],
      });
    },
    [channelId, currentData, libraryItems],
  );

  const checkItemsExistingInSavedLibrary = React.useCallback(
    (items: IChannelCatalogItem[]) => {
      if (isSearchActive) {
        const itemsExistingInSavedLibraryCopy = new Set(itemsExistingInSavedLibrary);
        const itemsExistingInSavedLibraryArray = [...itemsExistingInSavedLibraryCopy];

        items.forEach(item => {
          if (libraryItems.some(i => i.id === item.id)) {
            itemsExistingInSavedLibraryArray.push(item.id);
          }
        });

        setItemsExistingInSavedLibrary(new Set(itemsExistingInSavedLibraryArray));
      }
    },
    [isSearchActive, itemsExistingInSavedLibrary, libraryItems],
  );

  const removeItems = React.useCallback(
    (fromIndex: number[], items: IChannelCatalogItem[]): void => {
      checkItemsExistingInSavedLibrary(items);
      setLibraryItems(prevItems => {
        return prevItems.map(item => {
          if (items.some(i => i.id === item.id)) {
            return {...item, removed: true};
          }
          return item;
        });
      });
    },
    [checkItemsExistingInSavedLibrary],
  );

  const allLibraryItems = React.useMemo(() => {
    return [
      ...libraryItems
        .map(item => {
          if (newQueueItems.some(i => i.id === item.id)) {
            return {...item, removed: true};
          }
          return item;
        })
        .filter(item => !item.removed),
    ];
  }, [libraryItems, newQueueItems]);

  return {
    libraryItems: allLibraryItems,
    sort: getSortString(currentData?.metadata.sort),
    totalItems: error && (error as {status: number})?.status === 404 ? 0 : data?.metadata.totalCount || 0,
    isError: isError && (error as {status: number})?.status !== 404,
    isLazyLoading: isFetching && !loadingFirstPage,
    isLoading: isFetching && loadingFirstPage,
    isSuccess,
    handleLazyLoad,
    handleSorting,
    fetch,
    refetch,
    removeItems,
  };
};
