import * as React from 'react';
import {cloneDeep} from 'lodash-es';
import {
  Box,
  Button,
  ContentBoxes,
  ContentBox,
  Cluster,
  FormItem,
  Heading,
  ITableCol,
  Popover,
  Select,
  Spinner,
  Stack,
  Status,
  Table,
  TdLink,
  Template,
  TextInput,
  useValidateForm,
} from '@pluto-tv/assemble';

import {useLazyFindQuery as useFindVodCategoriesQuery} from 'features/vodCollections/vodCollectionsApi';
import {TableActions} from 'components/tableActions';

import {IEpisodeVodCategoryEntry, ISelectedCategory} from 'models/episodes';
import CrudError from 'components/crudError';
import vodCollectionRoutes from 'routes/programming.routes';

import {INestedEpisodeProps} from '../nestedPropsInterface';
import {episodeVodCategoryValidator} from '../../validators';
import {useEpisodePermissions} from '../../permissions/useEpisodePermissions';
import {useAppPermissions} from 'app/permissions';
import {popoverActionsProps} from 'helpers/popoverActionProps';

export default ({model, setFields, pristineModel}: INestedEpisodeProps): JSX.Element => {
  const {permissions, ableTo} = useAppPermissions();
  const {CAN_EDIT, editPermission} = useEpisodePermissions(pristineModel);

  const [vodCategoryIdBeingEdited, setVodCategoryIdBeingEdited] = React.useState('');
  const vodCategories = cloneDeep(model.vodCategoryEntries?.filter(vce => vce.vodCategory?.name) || []);

  const [searchVodCategories, vodCategoryItems] = useFindVodCategoriesQuery();

  const vodCategoriesDictionary = React.useMemo(() => {
    if (!model || !vodCategoryItems.isSuccess) return {};
    return vodCategoryItems.data.data.reduce((dic, cat) => {
      dic[cat.id] = cat;
      return dic;
    }, {});
  }, [model, vodCategoryItems]);

  React.useEffect(() => {
    if (model.id) {
      searchVodCategories({limit: 1000, activeRegions: [model.activeRegion as string], enabled: true});
    }
  }, [model, searchVodCategories]);

  const {
    form: selectedCategoryForm,
    model: selectedCategoryModel,
    setFields: selectedCategorySetFields,
    state: formState,
    reset: selectedCategoryReset,
  } = useValidateForm<ISelectedCategory>([...episodeVodCategoryValidator]);

  const isCategoryAddition = () => selectedCategoryModel && selectedCategoryModel.id === 'new';

  const cancelAddOrUpdateCategory = () => {
    setVodCategoryIdBeingEdited('');
    selectedCategoryReset();
  };

  const addOrUpdateCategoryEntry = () => {
    if (!selectedCategoryModel.category) {
      return;
    }

    const selectedVodCategory = vodCategoriesDictionary[selectedCategoryModel.category];
    if (isCategoryAddition()) {
      setFields({
        vodCategoryEntries: [
          ...(vodCategories || []),
          {
            id: `new-${Date.now()}`,
            episode: model.id || '',
            order: selectedCategoryModel.order || 1,
            vodCategory: selectedVodCategory,
            createdAt: new Date(),
            updatedAt: new Date(),
            changeType: 'I',
          },
        ],
      });
    } else {
      setFields({
        vodCategoryEntries: (vodCategories || []).map(voce => {
          if (voce.id !== selectedCategoryModel?.id) {
            return voce;
          }
          return {
            ...voce,
            order: selectedCategoryModel.order || 0,
            vodCategory: selectedVodCategory,
            updatedAt: new Date(),
            changeType: selectedCategoryModel.id.includes('new-') ? 'I' : 'U',
          };
        }),
      });
    }

    setVodCategoryIdBeingEdited('');
    selectedCategoryReset();
  };

  const handleAddCategory = () => {
    setVodCategoryIdBeingEdited('');
    selectedCategorySetFields({id: 'new', order: 1});
  };

  const handleEditCategory = (vodCategory: IEpisodeVodCategoryEntry) => {
    setVodCategoryIdBeingEdited(vodCategory.vodCategory.id);
    selectedCategorySetFields({
      id: vodCategory.id,
      category: vodCategory.vodCategory.id,
      order: vodCategory.order,
    });
  };

  const handleDeleteCategory = (deletedCategory: IEpisodeVodCategoryEntry) => {
    const cts = (vodCategories || [])
      .map(voce => {
        if (voce.id !== deletedCategory?.id) {
          return voce;
        }

        return voce.changeType === 'D' || voce.changeType === 'I'
          ? {...voce, changeType: 'X'}
          : {...voce, changeType: 'D'};
      })
      .filter(voce => voce.changeType !== 'X');

    setFields({
      vodCategoryEntries: cts as IEpisodeVodCategoryEntry[],
    });
  };

  if (vodCategoryItems.isError && (vodCategoryItems?.error as any)?.data.statusCode !== 404) {
    return <CrudError error='Error loading page data' />;
  }

  if (vodCategoryItems.isLoading) {
    return (
      <Box fullHeight={true}>
        <Spinner id='vod-collections-spinner' center={true} minHeight='9.375rem' size='xlarge' />
      </Box>
    );
  }

  return (
    <ContentBoxes layout='cover' coverScrolling={true}>
      <Template label='contentBoxesCover'>
        <ContentBox>
          <Template label='header'>
            <Cluster justify='space-between' align='center'>
              <Heading level='h3' color='secondary'>
                VOD Collections
              </Heading>
              {CAN_EDIT && (
                <Popover
                  manualTrigger={true}
                  visible={selectedCategoryModel?.id !== undefined}
                  onClickOutside={() => cancelAddOrUpdateCategory()}
                  permission={editPermission && permissions.VOD_EDIT}
                  appendToBody={true}
                >
                  <Template label='trigger'>
                    <Button
                      type='primary'
                      permission={editPermission && permissions.VOD_EDIT}
                      onClick={() => handleAddCategory()}
                      state={
                        vodCategoryItems.isLoading
                          ? 'thinking'
                          : (vodCategoryItems?.error as any)?.status === 404
                          ? 'disabled'
                          : ''
                      }
                    >
                      + Add
                    </Button>
                  </Template>
                  <Template label='popover'>
                    <Box
                      id='add-collection-popover'
                      padding={popoverActionsProps.padding}
                      background={popoverActionsProps.background}
                      width={popoverActionsProps.width}
                    >
                      <Stack space='small'>
                        <FormItem {...selectedCategoryForm.category}>
                          <Select
                            placeholder='Collection Name'
                            id='category'
                            predicate='value'
                            onChange={value => selectedCategorySetFields({category: value.value})}
                            value={{
                              label: selectedCategoryModel.category
                                ? vodCategoriesDictionary[selectedCategoryModel.category].name
                                : '',
                              value: selectedCategoryModel.category,
                            }}
                            searchable={true}
                            searchPlaceholder='Search Categories'
                            onSearch={val =>
                              Object.keys(vodCategoriesDictionary)
                                .filter(
                                  vodCat =>
                                    vodCat === vodCategoryIdBeingEdited ||
                                    !vodCategories.some(existing => existing.vodCategory.id === vodCat),
                                )
                                .map(vci => ({
                                  label: vodCategoriesDictionary[vci].name,
                                  value: vci,
                                }))
                                .filter(vc => vc.label.toLowerCase().indexOf(val.toLowerCase()) > -1) || []
                            }
                            options={Object.keys(vodCategoriesDictionary)
                              .filter(
                                vodCat =>
                                  vodCat === vodCategoryIdBeingEdited ||
                                  !vodCategories.some(existing => existing.vodCategory.id === vodCat),
                              )
                              .map(vci => ({
                                label: vodCategoriesDictionary[vci].name,
                                value: vci,
                              }))}
                          />
                        </FormItem>
                        <FormItem {...selectedCategoryForm.order}>
                          <TextInput
                            id='number-input'
                            type='number'
                            value={selectedCategoryModel.order}
                            onChange={value => selectedCategorySetFields({order: value})}
                          />
                        </FormItem>
                        <Cluster justify='space-between'>
                          <div></div>
                          <Cluster space='small'>
                            <Button ghost={true} onClick={() => cancelAddOrUpdateCategory()}>
                              Cancel
                            </Button>
                            <Button
                              id='add-collection-popover-button'
                              state={!formState.isValid || !formState.isDirty ? 'disabled' : ''}
                              type='primary'
                              onClick={() => addOrUpdateCategoryEntry()}
                            >
                              {isCategoryAddition() ? '+ Add' : 'Update'}
                            </Button>
                          </Cluster>
                        </Cluster>
                      </Stack>
                    </Box>
                  </Template>
                </Popover>
              )}
            </Cluster>
          </Template>
          <Template label='content'>
            <Table
              flushTop={true}
              fixedHeader={true}
              emptyMsg={
                (vodCategoryItems?.error as any)?.status === 404
                  ? 'No VOD collections available for the selected active region'
                  : 'No VOD Collections'
              }
              cols={[
                {
                  label: 'Collection Name',
                  colMaxWidth: '25rem',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.vodCategory.name}
                      target='_blank'
                      url={vodCollectionRoutes.paths.vodCollectionEditProgramPage.replace(':id', row.vodCategory.id)}
                    />
                  ),
                },
                {
                  label: 'Display Name',
                  transform: row => row.vodCategory.displayName,
                },
                {
                  label: 'Published',
                  colMinWidth: '6.875rem',
                  transform: row => (
                    <Status
                      label={row.vodCategory.enabled ? 'Published' : 'Unpublished'}
                      state={row.vodCategory.enabled ? 'success' : 'neutral'}
                    />
                  ),
                },
                {
                  label: 'Archived',
                  transform: row => (row.vodCategory.archived ? 'Yes' : 'No'),
                },
                {
                  label: 'Order',
                  transform: row => row.order,
                },
                ...(CAN_EDIT && ableTo('VOD_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            icons={['edit']}
                            deleteOption={ableTo('SERIES_DELETE') && ableTo('VOD_EDIT')}
                            altTitle='collection'
                            onClick={(row, icon) => {
                              switch (icon) {
                                case 'edit':
                                  handleEditCategory(row);
                                  break;
                                case 'delete':
                                  handleDeleteCategory(row);
                                  break;
                                default:
                              }
                            }}
                          />
                        ),
                      } as ITableCol<IEpisodeVodCategoryEntry>,
                    ]
                  : []),
              ]}
              rows={vodCategories.filter(voc => voc.changeType !== 'D')}
            ></Table>
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
};
