import React from 'react';
import {ICarouselConfigSearchQuery} from 'features/carouselConfigs/carouselConfigsApi';
import {ICarouselConfig} from 'models/carouselConfigs';
import {TSortDirection} from 'models/generic';

const INIT_PARAMS = {
  sortBy: 'createdAt',
  sortDirection: 'dsc' as TSortDirection,
  limit: 50,
  offset: 0,
};

export interface ISearchCarouselData<ISearchData, ICarouselConfigSearchQuery> {
  data: ISearchData;
  totalCount: number;
  currentData: ISearchData;
  isFetching: boolean;
  isError: boolean;
  isLoading: boolean;
  search: (params: ICarouselConfigSearchQuery) => any;
}

const useSearchCarouselData = <T, U extends Record<string, unknown>>(
  useLazyFindQueryHook: () => [
    (params: ICarouselConfigSearchQuery) => any,
    {
      data: T;
      totalCount: number;
      currentData: T;
      isLoading: boolean;
      isFetching: boolean;
      isError: boolean;
      error: Error;
      isSuccess: boolean;
    },
  ],
): ISearchCarouselData<T, U> => {
  const [trigger, {currentData, isLoading, isFetching, isError}] = useLazyFindQueryHook();

  const [allData, setAllData] = React.useState<{data: ICarouselConfig[]; totalCount: number}>({
    data: [],
    totalCount: 0,
  });
  const [allTotalCount, setAllTotalCount] = React.useState(0);

  const doSearch = React.useCallback(
    async (params: ICarouselConfigSearchQuery) => {
      const paramsList: ICarouselConfigSearchQuery[] = [{...INIT_PARAMS, ...params}];

      /**
       * We need to make two separate API calls to include the "ALL" option, as the service-carousel-config doesn't
       * support handling this request in a single call such as `...?region=ALL&region=US`.
       */
      // if (params.region && params.region.length && !params.region.includes('ALL')) {
      //   paramsList.push({
      //     ...INIT_PARAMS,
      //     ...params,
      //     region: 'ALL',
      //   });
      // }

      try {
        const promises = paramsList.map(params => trigger({...params, time: new Date().getTime()}).unwrap());
        const promiseResponse = await Promise.all(promises);

        const data = promiseResponse
          .reduce((tmp: any, res: any) => [...tmp, ...res.data], [])
          .sort((a: ICarouselConfig, b: ICarouselConfig) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
          ) as ICarouselConfig[];

        const totalCount = promiseResponse.reduce((tmp: any, res: any) => tmp + res.totalCount, 0);

        setAllData({data, totalCount});
        setAllTotalCount(totalCount);

        return {data, totalCount};
      } catch (error) {
        setAllData({data: [], totalCount: 0});
        setAllTotalCount(0);

        return {data: [], totalCount: 0};
      }
    },
    [trigger],
  );

  return {
    data: allData as T,
    currentData,
    totalCount: allTotalCount,
    isLoading,
    isFetching,
    isError,
    search: doSearch as any,
  };
};

export default useSearchCarouselData;
